import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { FaFileAlt, FaTimes, FaEdit, FaSave, FaUserLock } from 'react-icons/fa';

import api from '~/services/api';

import formatDocument from '~/util/formatDocument';
import formatContact from '~/util/formatContact';

import { FormLoading, Input, InputMask, DatePicker } from '~/components/Form';
import Modal from '~/components/Modal';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import { Header, FormContainer, DetailsContainer } from '~/styles/components';

import { Container, Controls, Content, AvatarInput } from './styles';

import ResetPassword from '../ResetPassword';

const MyData = ({ isOpen, setIsOpen }) => {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [myData, setMyData] = useState(null);
  const [myDataForm, setMyDataForm] = useState(null);
  const [myDataFormIds, setMyDataFormIds] = useState(null);
  const [uploadAvatar, setUploadAvatar] = useState(null);

  const [toggleResetPassword, setToggleResetPassword] = useState(false);

  useEffect(() => {
    async function formatMyData() {
      try {
        setLoading(true);

        const response = await api.get('company-users/get/data');

        const companyUser = response.data;

        if (!isEditing) {
          const formattedMyData = {
            name: companyUser.user.name || '',
            nick: companyUser.user.nick || '',
            email: companyUser.user.email || '',
            avatar: companyUser.user?.avatar || null,
            avatar_url: companyUser.user?.avatar
              ? companyUser.user.avatar_url
              : null,
            birthday: companyUser.user?.birthday
              ? format(parseISO(companyUser.user.birthday), 'dd/MM/yyyy')
              : '',
            phone: formatContact(
              3,
              companyUser?.relationship?.contacts?.find(
                contact => contact.type === 3
              )?.content || ''
            ),
            document: formatDocument(
              1,
              companyUser?.relationship?.document || ''
            ),
            pin: companyUser.pin || '',
          };

          setMyData(formattedMyData);
        } else {
          const formattedMyData = {
            name: companyUser.user.name || '',
            nick: companyUser.user.nick || '',
            email: companyUser.user.email || '',
            avatar: companyUser.user?.avatar || null,
            avatar_url: companyUser.user?.avatar
              ? companyUser.user.avatar_url
              : null,
            birthday: companyUser?.user?.birthday,
            phone:
              companyUser?.relationship?.contacts?.find(
                contact => contact.type === 3
              )?.content || null,
            document: companyUser?.relationship?.document || '',
            pin: companyUser.pin || '',
          };

          const ids = {
            user_id: companyUser.user.id,
            old_user_id: companyUser.user.old_id,
            company_id: companyUser.user.main_company,
            old_company_id: companyUser.user.old_main_company,
            company_user_id: companyUser.id,
            relationship_id: companyUser.relationship
              ? companyUser?.relationship.id
              : null,
            old_relationship_id: companyUser.relationship
              ? companyUser?.relationship.old_id
              : null,
            email_id: companyUser.relationship
              ? companyUser?.relationship?.contacts.find(
                  contact => contact.type === 1
                )?.id
              : null,
            phone_id: companyUser.relationship
              ? companyUser?.relationship?.contacts.find(
                  contact => contact.type === 3
                )?.id
              : null,
          };

          setMyDataForm(formattedMyData);
          setMyDataFormIds(ids);
        }
      } catch (err) {
        toast.error('Falha ao carregar dados', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
    formatMyData();
  }, [isEditing]);

  const handleSubmit = useCallback(
    async data => {
      if (myDataFormIds) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            document: Yup.string().required('O documento é obrigatório.'),
            email: Yup.string().required('O e-mail é obrigatório.'),
            birthday: Yup.string()
              .nullable()
              .required('A data de nascimento é obrigatória.'),
            phone: Yup.string().required('O telefone é obrigatório.'),
            name: Yup.string().required('O nome é obrigatório.'),
            nick: Yup.string(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const {
            relationship_id,
            old_relationship_id,
            email_id,
            phone_id,
            company_id,
            old_company_id,
            user_id,
            old_user_id,
            company_user_id,
          } = myDataFormIds;

          const contacts = [
            {
              id: email_id,
              owner_id: relationship_id,
              type: 1,
              content: data.email,
            },
            {
              id: phone_id,
              owner_id: relationship_id,
              type: 3,
              content: data.phone.replace(/[^0-9]+/g, ''),
            },
          ];

          if (uploadAvatar) {
            const formData = new FormData();

            formData.append('file', uploadAvatar);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'User_Avatar',
              },
            });

            const { blobName } = fileResponse.data;

            await api.put(`users/${user_id}`, {
              avatar: blobName,
            });
          }

          const user_nick =
            data.nick.trim() !== '' ? data.nick : data.name.split(' ')[0];

          await Promise.all([
            api.post(
              'relationships/contacts',
              {
                company_id,
                contacts,
              },
              {
                params: {
                  old_company_id,
                  mainid: old_user_id,
                  relationship_id,
                  old_relationship_id,
                },
              }
            ),
            api.put(`users/${user_id}`, {
              name: data.name,
              nick: user_nick,
              email: data.email,
              birthday: data.birthday,
              document: data.document.replace(/[^0-9]+/g, ''),
              relationship_id,
            }),
            api.put(`company-users/${company_user_id}`, {
              pin: data.pin,
            }),
          ]);

          toast.success('Dados salvos com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);
          setIsEditing(false);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error(
              `${err.response.data.message || 'Falha ao salvar dados.'}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }

          setSaveLoading(false);
        }
      }
    },
    [myDataFormIds, uploadAvatar]
  );

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => setIsEditing(false)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleUpdateAvatar = useCallback(avatar => {
    setUploadAvatar(avatar);

    const avatarUrl = URL.createObjectURL(avatar);

    setMyDataForm(oldMyDataForm => ({
      ...oldMyDataForm,
      avatar_url: avatarUrl,
    }));
  }, []);

  const handleToggleResetPassword = useCallback(() => {
    setToggleResetPassword(!toggleResetPassword);
  }, [toggleResetPassword]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaFileAlt size={20} color="#44546a" />
            <h1>Meus Dados e Senha</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Controls>
          {!isEditing ? (
            <>
              <button type="button" onClick={handleEdit}>
                <FaEdit size={15} color="#44546a" />
                <span>Editar</span>
              </button>
              <button type="button" onClick={handleToggleResetPassword}>
                <FaUserLock size={15} />
                <span>Alterar senha</span>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => formRef.current.submitForm()}
              >
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button" onClick={handleClose}>
                <FaTimes size={15} color="#44546a" />
                <span style={{ color: '#44546a' }}>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        <Content className="content">
          {!isEditing ? (
            <>
              {loading ? (
                <FormLoading />
              ) : (
                <>
                  {myData && (
                    <DetailsContainer>
                      <section>
                        <AvatarInput>
                          {myData.avatar_url ? (
                            <img src={myData.avatar_url} alt={myData.name} />
                          ) : (
                            <img
                              src={`https://avatar.oxro.io/avatar?name=${myData.name}&background=c7c7c7`}
                              alt={myData.name}
                            />
                          )}
                        </AvatarInput>
                      </section>
                      <section>
                        <div className="document">
                          <label>CPF</label>
                          <input
                            name="document"
                            value={myData.document}
                            readOnly
                          />
                        </div>
                        <div className="name">
                          <label>Nome</label>
                          <input name="name" value={myData.name} readOnly />
                        </div>
                        <div className="name">
                          <label>Tratamento</label>
                          <input name="nick" value={myData.nick} readOnly />
                        </div>
                      </section>
                      <section>
                        <div className="birthday">
                          <label>Data de nascimento</label>
                          <input
                            name="birthday"
                            value={myData.birthday}
                            readOnly
                          />
                        </div>
                        <div className="email">
                          <label>E-mail</label>
                          <input name="email" value={myData.email} readOnly />
                        </div>
                        <div className="phone">
                          <label>Telefone</label>
                          <input name="phone" value={myData.phone} readOnly />
                        </div>
                      </section>
                      <section>
                        <div className="pin">
                          <label>PIN</label>
                          <input name="pin" value={myData.pin} readOnly />
                        </div>
                      </section>
                    </DetailsContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <FormLoading />
              ) : (
                <>
                  {myDataForm !== null && (
                    <FormContainer
                      ref={formRef}
                      onSubmit={handleSubmit}
                      initialData={myDataForm}
                    >
                      <section>
                        <AvatarInput>
                          {myDataForm.avatar_url ? (
                            <img
                              src={myDataForm.avatar_url}
                              alt={myDataForm.name}
                            />
                          ) : (
                            <img
                              src={`https://avatar.oxro.io/avatar?name=${myDataForm.name}&background=c7c7c7`}
                              alt={myDataForm.name}
                            />
                          )}
                          <label htmlFor="avatar">
                            <span>Alterar imagem</span>
                          </label>
                          <input
                            id="avatar"
                            type="file"
                            name="avatar"
                            onChange={e =>
                              handleUpdateAvatar(e.target.files[0])
                            }
                          />
                        </AvatarInput>
                      </section>
                      <section>
                        <InputMask
                          name="document"
                          className="document"
                          label="CPF"
                          mask="999.999.999-99"
                        />
                        <Input name="name" className="name" label="Nome" />
                        <Input
                          name="nick"
                          className="name"
                          label="Tratamento"
                        />
                      </section>
                      <section>
                        <DatePicker
                          name="birthday"
                          className="birthday"
                          label="Data de nascimento"
                        />
                        <Input
                          name="email"
                          className="email"
                          label="E-mail"
                          readOnly
                        />
                        <InputMask
                          name="phone"
                          className="phone"
                          label="Telefone"
                          mask="(99) 99999-9999"
                        />
                      </section>
                      <section>
                        <Input name="pin" className="pin" label="PIN" />
                      </section>
                    </FormContainer>
                  )}
                </>
              )}
            </>
          )}
        </Content>
      </Container>
      {saveLoading && <Loading />}

      {toggleResetPassword && (
        <ResetPassword
          isOpen={toggleResetPassword}
          setIsOpen={handleToggleResetPassword}
        />
      )}
    </Modal>
  );
};

export default MyData;
