import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaTimes, FaUserFriends, FaSave, FaTimesCircle } from 'react-icons/fa';

import { useAuth } from '~/hooks';
import * as Yup from 'yup';

import api from '~/services/api';

import formatDocument from '~/util/formatDocument';

import { Input, Select, DatePicker, InputMask } from '~/components/Form';
import { TableLoading } from '~/components/Table';

import { Container, Header, Controls } from '~/styles/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmWindow from '~/components/ConfirmWindow';
import { confirmAlert } from 'react-confirm-alert';
import { Content, EmployeeForm } from './styles';

const Edit = ({ match }) => {
  const { company } = useAuth();
  const formRef = useRef(null);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState({});
  const [clientsOptions, setClientsOptions] = useState([]);
  const [isResignationEnabled, setIsResignationEnabled] = useState(false);

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        const options = response.data.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setClientsOptions(options);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const loadEmployee = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get(`/employees/${match.params.id}`);

        if (response.data) {
          const formattedEmployee = {
            ...response.data,
            client_id: response.data.client.id,
            admission_date: response.data.admission_date,
            resignation_date: response.data.resignation_date,
            attest_date: response.data.attest_date,
            first_ctto_date: response.data.first_ctto_date,
            second_ctto_date: response.data.second_ctto_date,
            cpf: response.data.cpf ? formatDocument(1, response.data.cpf) : '',
            situation: response.data.situation,
          };

          setEmployee(formattedEmployee);
          setIsResignationEnabled(response.data.situation === 1);
          setClientsOptions(prevOptions => [
            ...prevOptions,
            {
              label: response.data.client.name,
              value: response.data.client.id,
            },
          ]);
        } else {
          toast.warn('Nenhum funcionário foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar funcionários.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company, match.params.id]);

  useEffect(() => {
    loadClients();
    loadEmployee();
  }, [loadClients, loadEmployee]);

  const employeeSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('CPF é obrigatório')
      .min(14, 'CPF deve ter no mínimo 14 caracteres'),
    name: Yup.string().required('Nome é obrigatório'),
    client_id: Yup.string().required('Cliente é obrigatório'),
    situation: Yup.number().required('Situação é obrigatória'),
    admission_date: Yup.date().nullable(),
    resignation_date: Yup.date().nullable(),
    attest_date: Yup.date().nullable(),
    first_ctto_date: Yup.date().nullable(),
    second_ctto_date: Yup.date().nullable(),
  });

  const handleSave = useCallback(async () => {
    try {
      const formattedEmployee = {
        ...employee,
        admission_date: employee.admission_date
          ? employee.admission_date.toISOString()
          : null,
        resignation_date: employee.resignation_date
          ? employee.resignation_date.toISOString()
          : null,
        attest_date: employee.attest_date
          ? employee.attest_date.toISOString()
          : null,
        first_ctto_date: employee.first_ctto_date
          ? employee.first_ctto_date.toISOString()
          : null,
        second_ctto_date: employee.second_ctto_date
          ? employee.second_ctto_date.toISOString()
          : null,
      };
      await employeeSchema.validate(formattedEmployee, { abortEarly: false });

      await api.put(`/employees/${match.params.id}`, formattedEmployee);

      toast.success('Funcionário alterado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/employee-registration');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Falha ao salvar funcionário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [employee, employeeSchema, history, match.params.id]);

  const confirmSaveForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={handleSave}
            onClose={onClose}
            message="Todos os dados serão salvos com esta ação."
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleSave]);

  const handleCancel = useCallback(() => {
    history.push('/employee-registration');
  }, [history]);

  const confirmCalcelForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleCancel} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleCancel]);

  const handleChange = (e, name) => {
    const { value } = e.target;
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleSelectChange = selectedOption => {
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      client_id: selectedOption.value,
    }));
  };

  const handleSituationChange = selectedOption => {
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      situation: selectedOption.value,
    }));

    if (selectedOption.value === 1) {
      setIsResignationEnabled(true);
    } else {
      setIsResignationEnabled(false);
      setEmployee(prevEmployee => ({
        ...prevEmployee,
        resignation_date: null,
      }));
    }
  };

  const formatCPF = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const handleCPFChange = e => {
    const { value } = e.target;
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      cpf: formatCPF(value),
    }));
  };

  const situationOptions = [
    { value: 0, label: 'Admitido' },
    { value: 1, label: 'Demitido' },
  ];

  return (
    <Container>
      <Header>
        <div>
          <FaUserFriends />
          <h1>Cadastro de Empregados</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={confirmSaveForm}>
          <FaSave size={15} color="#44546a" />
          <span>Salvar</span>
        </button>
        <button type="button" onClick={confirmCalcelForm}>
          <FaTimesCircle />
          <span>Cancelar</span>
        </button>
      </Controls>
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {employee && (
            <EmployeeForm ref={formRef} onSubmit={handleSave}>
              <h4>Funcionários</h4>
              <section>
                <InputMask
                  name="cpf"
                  label="CPF"
                  value={employee.cpf}
                  onChange={handleCPFChange}
                  mask="999.999.999-99"
                  maskChar={null}
                />
                <Input
                  name="name"
                  className="name"
                  label="Nome"
                  defaultValue={employee?.name || ''}
                  onChange={e => handleChange(e, 'name')}
                />
                <Select
                  label="Cliente"
                  name="client_id"
                  className="client"
                  options={clientsOptions}
                  placeholder="Selecione um cliente"
                  defaultValue={clientsOptions.find(
                    option => option.value === employee.client_id
                  )}
                  onChange={handleSelectChange}
                />
                <div className="situation-container">
                  <Select
                    name="situation"
                    className="situation"
                    label="Situação"
                    type="text"
                    options={situationOptions}
                    placeholder="Selecione a situação"
                    onChange={handleSituationChange}
                    defaultValue={situationOptions.find(
                      option => option.value === employee.situation
                    )}
                  />
                </div>
              </section>
              <section>
                <DatePicker
                  name="admission_date"
                  label="Admissão"
                  selected={
                    employee?.admission_date
                      ? new Date(employee?.admission_date)
                      : null
                  }
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      admission_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
                <DatePicker
                  label={
                    isResignationEnabled
                      ? 'Demissão'
                      : 'Demissão (Desabilitado)'
                  }
                  name="resignation_date"
                  selected={
                    employee?.resignation_date
                      ? new Date(employee?.resignation_date)
                      : null
                  }
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      resignation_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled={!isResignationEnabled}
                  placeholderText="Selecione uma data"
                />
                <DatePicker
                  name="attest_date"
                  label="Data de atestado"
                  selected={
                    employee?.attest_date
                      ? new Date(employee?.attest_date)
                      : null
                  }
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      attest_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
                <DatePicker
                  name="first_ctto_date"
                  label="Fim Etapa 1 Ctto"
                  selected={
                    employee?.first_ctto_date
                      ? new Date(employee?.first_ctto_date)
                      : null
                  }
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      first_ctto_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
                <DatePicker
                  name="second_ctto_date"
                  label="Fim Etapa 2 Ctto"
                  selected={
                    employee?.second_ctto_date
                      ? new Date(employee?.second_ctto_date)
                      : null
                  }
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      second_ctto_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
              </section>
            </EmployeeForm>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Edit;
