import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaSave,
  FaTrash,
  FaBroom,
  FaTimes,
  FaPlus,
  FaPaperclip,
  FaAngleDown,
  FaAngleUp,
  FaMinus,
  FaExchangeAlt,
  FaFolderOpen,
} from 'react-icons/fa';

import produce from 'immer';
import * as Yup from 'yup';
import { Scope } from '@unform/core';

import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Input,
  FileInput,
  Select,
  Checkbox,
  DatePicker,
  InputMask,
  TextArea,
} from '~/components/Form';
import AlreadyExistsDocuments from './AlreadyExistsDocuments';

import {
  Container,
  Header,
  Controls,
  Content,
  ProtocolInfo,
  Title,
  Options,
  File,
  UploadFile,
  Delete,
} from './styles';

const Form = () => {
  const { state } = useLocation();
  const id = state?.id || null;

  const { user, company } = useAuth();

  const formRef = useRef();

  const [protocolLoading, setProtocolLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [protocol, setProtocol] = useState({});
  const deleteDocuments = useRef([]);
  const deleteViewers = useRef([]);

  const [changeOrderDocuments, setChangeOrderDocuments] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [documentProblem, setDocumentProblem] = useState([]);

  const [clientsOptions, setClientsOptions] = useState(null);
  const [viewersOptions, setViewersOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState({
    value: 0,
    label: 'Portal',
  });
  const [documentsOptions, setDocumentsOptions] = useState(null);

  const [
    requiredDocumentErrorMessage,
    setRequiredDocumentErrorMessage,
  ] = useState(null);
  const [requiredViewerErrorMessage, setRequiredViewerErrorMessage] = useState(
    null
  );

  const [initialData, setInitialData] = useState({});

  const methodOptions = useMemo(() => {
    return [
      { value: 0, label: 'Portal' },
      { value: 1, label: 'Impressão' },
      { value: 2, label: 'Upload' },
      { value: 3, label: 'Arquivo morto' },
    ];
  }, []);
  const situationOptions = useMemo(() => {
    return [
      { value: 0, label: 'Gerado' },
      { value: 1, label: 'Enviado' },
      { value: 2, label: 'Entregue' },
      { value: 3, label: 'Impresso' },
      { value: 4, label: 'Impresso/Enviado' },
      { value: 5, label: 'Cancelado' },
    ];
  }, []);

  useEffect(() => {
    async function loadRelated() {
      if (selectedClient && !protocolLoading) {
        try {
          const response = await api.get(
            `/relationships/related/${selectedClient}`
          );

          const { data } = response;

          if (data.length > 0) {
            const options = [];

            data.forEach(item => {
              item.related_contacts.forEach(contact => {
                options.push({
                  value: contact.id,
                  relationship_id: item.info.id,
                  label: `${item.info.name} - ${contact.content}`,
                  dpt_accounting: item.dpt_accounting,
                  dpt_admin: item.dpt_admin,
                  dpt_financial: item.dpt_accounting,
                  dpt_labour: item.dpt_labour,
                  dpt_tax: item.dpt_tax,
                  alreadyUsed: !!protocol.viewers.find(
                    viewer => viewer.contact_id === contact.id
                  ),
                });
              });
            });

            setViewersOptions(options);
          } else {
            toast.warn('Nenhuma pessoa relacionada foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } catch {
          toast.error('Falha ao buscar pessoas relacionadas.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadRelated();
    // eslint-disable-next-line
  }, [selectedClient, protocolLoading]);

  useEffect(() => {
    async function loadClients() {
      if (user && company) {
        try {
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            const options = response.data.map(item => ({
              value: item.id,
              label: item.name,
              preference: item.protocol_preference,
            }));

            setClientsOptions(options);
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setClientsOptions([]);
          }
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    async function loadDocuments() {
      if (user && company) {
        try {
          const response = await api.get(`/documents/select/${company.id}`);

          if (response.data.length > 0) {
            const options = response.data.map(item => ({
              value: item.document.id,
              label: item.document.description,
              titleToPay: item.document.title_to_pay,
              auto_upload: item.document.auto_upload,
            }));

            setDocumentsOptions(options);
          } else {
            toast.warn('Nenhum documento foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setDocumentsOptions([]);
          }
        } catch {
          toast.error('Falha ao buscar documentos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    async function loadProtocol() {
      if (user && company) {
        if (id) {
          try {
            setProtocolLoading(true);

            const response = await api.get(`/protocols/${id}`);
            const { data } = response;
            const {
              documents,
              viewers,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              client_id,
            } = data;

            data.documents = documents.map(document => ({
              ...document,
              price: document.price.replace(',', '.'),
              deadline_date: document.deadline_date
                ? format(parseISO(document.deadline_date), 'dd/MM/yyyy')
                : '',
            }));

            data.viewers = viewers.map(viewer => ({
              id: viewer.id,
              contact_id: viewer.contact_id,
              relationship_id: viewer.relationship_id,
            }));

            data.start_date = parseISO(data.created_at);

            setSelectedClient(client_id);
            setProtocol(data);
            setInitialData({
              start_date: data.start_date || new Date(),
              labor,
              tributary,
              accounting,
              financial,
              administration,
            });

            setProtocolLoading(false);
          } catch {
            toast.error('Falha ao buscar protocolo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setProtocolLoading(false);
          }
        } else {
          setProtocol({
            start_date: new Date(),
            situation: 0,
            client: null,
            obs: '',
            method: null,
            labor: false,
            tributary: false,
            accounting: false,
            financial: false,
            administration: false,
            documents: [],
            viewers: [],
          });
          setInitialData({
            labor: false,
            tributary: false,
            accounting: false,
            financial: false,
            administration: false,
          });
          setProtocolLoading(false);
        }
      }
    }

    loadClients();
    loadDocuments();
    loadProtocol();
  }, [id, user, company]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        const hasSomeFile = protocol.documents
          .map(item => item.file_target)
          .some(item => item);

        const schema = Yup.object().shape({
          client: Yup.number().typeError('O cliente é obrigatório'),
          obs: Yup.string(),
          method: Yup.number().typeError('O método é obrigatório'),
          labor: Yup.bool(),
          tributary: Yup.bool(),
          accounting: Yup.bool(),
          financial: Yup.bool(),
          administration: Yup.bool(),
          documents: Yup.array()
            .min(1)
            .of(
              Yup.object().shape({
                document_id: Yup.string().required('O documento é obrigatório'),
                competence: Yup.string().required(
                  'A comepetência é obrigatória'
                ),
                final_competence:
                  data.method === 3
                    ? Yup.string().required('A competencia final é obrigatório')
                    : Yup.string(),
                deadline_date: Yup.string().when('document_id', {
                  is: documentValue =>
                    documentsOptions.find(
                      option => option.value === documentValue
                    )?.titleToPay && data.method !== 3,
                  then: Yup.string().required('O vencimento é obrigatório'),
                  otherwise: Yup.string(),
                }),
                price: Yup.string().when('document_id', {
                  is: documentValue =>
                    documentsOptions.find(
                      option => option.value === documentValue
                    )?.titleToPay && data.method !== 3,
                  then: Yup.string().required('O preço é obrigatório'),
                  otherwise: Yup.string(),
                }),
                obs: Yup.string(),
                file:
                  data.method === 0 || data.method === 2
                    ? Yup.string().required('O arquivo é obrigatório')
                    : Yup.string(),
              })
            )
            .required('Pelo menos um documento é obrigatório.'),
          viewers: Yup.array().when('method', {
            is: value => value && (value === 1 || value === 3) && !hasSomeFile,
            then: Yup.array().of(
              Yup.object().shape({
                contact_id: Yup.number().typeError('O usuário é obrigatória'),
              })
            ),
            otherwise: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  contact_id: Yup.number().typeError('O usuário é obrigatória'),
                })
              )
              .required('Pelo menos uma pessoa para enviar é obrigatório.'),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          client_id,
          obs,
          method,
          accounting,
          administration,
          financial,
          labor,
          tributary,
        } = data;

        const protocolData = {
          user_id: user.id,
          company_id: company.id,
          client_id,
          situation: method === 2 ? 2 : 0,
          receiver: method === 2 ? 'UPLOAD' : null,
          obs,
          method,
          accounting,
          administration,
          financial,
          labor,
          tributary,
        };

        const protocolDocumentsFormatted = protocol.documents.map(item => {
          let priceFormatted = '';

          if (item.price) {
            priceFormatted = parseFloat(item.price.replace(',', '.'));
            priceFormatted = priceFormatted.toFixed(2).replace('.', ',');
          }

          return {
            ...item,
            price: priceFormatted,
            substitute_cod: item.protocolSubstituted || item.substitute_cod,
          };
        });

        if (id) {
          await api.put(`/protocols/${id}`, {
            obs,
            method,
            accounting,
            administration,
            financial,
            labor,
            tributary,
          });

          const documentsPromises = protocolDocumentsFormatted.map(
            async (document, index) => {
              const {
                order,
                id: id_document,
                file_target,
                deadline_date,
                documentSubstituted,
                ...rest
              } = document;

              const splitDeadline = deadline_date.split('/');
              const formattedDeadline = new Date(
                `${splitDeadline[2]}/${splitDeadline[1]}/${splitDeadline[0]}`
              );

              if (file_target) {
                const formData = new FormData();

                formData.append('file', file_target);

                const fileResponse = await api.post('files/upload', formData, {
                  params: {
                    prefix: 'Protocol_Document',
                  },
                });

                const { blobName } = fileResponse.data;

                const file_url = blobName;

                if (id_document) {
                  await api.put(`/protocols/documents/${id_document}`, {
                    ...rest,
                    file_url,
                    order: index,
                    deadline_date: formattedDeadline,
                  });
                } else {
                  await api.post('/protocols/documents', {
                    ...rest,
                    file_url,
                    order: index,
                    deadline_date: formattedDeadline,
                  });
                }
              } else if (id_document) {
                await api.put(`/protocols/documents/${id_document}`, {
                  ...rest,
                  order: index,
                  deadline_date: formattedDeadline,
                });
              } else {
                await api.post('/protocols/documents', {
                  ...rest,
                  order: index,
                  deadline_date: formattedDeadline,
                });
              }

              if (documentSubstituted) {
                await api.put(`/protocols/documents/${documentSubstituted}`, {
                  substituted: true,
                  substitute_cod: protocol.cod,
                });
              }
            }
          );

          const viewersPromises = protocol.viewers.map(async viewer => {
            const { id: id_viewer, contact_id, relationship_id } = viewer;

            if (!id_viewer) {
              await api.post('/protocols/viewers', {
                contact_id,
                relationship_id,
                protocol_id: id,
              });
            }
          });

          const deleteDocumentsPromises = deleteDocuments.current.map(
            async id_document => {
              await api.delete(`/protocols/documents/${id_document}`);
            }
          );

          const deleteViewersPromises = deleteViewers.current.map(
            async id_viewer => {
              await api.delete(`/protocols/viewers/${id_viewer}`);
            }
          );

          await Promise.all(documentsPromises);
          await Promise.all(viewersPromises);

          await Promise.all(deleteDocumentsPromises);
          await Promise.all(deleteViewersPromises);
        } else {
          const createdProtocol = await api.post(`/protocols`, protocolData);

          const documentsPromises = protocolDocumentsFormatted.map(
            async (document, index) => {
              const {
                order,
                file_target,
                deadline_date,
                documentSubstituted,
                protocol_id,
                ...rest
              } = document;

              const splitDeadline = deadline_date.split('/');
              const formattedDeadline = new Date(
                `${splitDeadline[2]}/${splitDeadline[1]}/${splitDeadline[0]}`
              );

              if (file_target) {
                const formData = new FormData();

                formData.append('file', file_target);

                const fileResponse = await api.post('files/upload', formData, {
                  params: {
                    prefix: 'Protocol_Document',
                  },
                });

                const { blobName: file_url } = fileResponse.data;

                await api.post('/protocols/documents', {
                  protocol_id: createdProtocol.data.id,
                  ...rest,
                  file_url,
                  deadline_date: formattedDeadline,
                  order: index,
                });

                if (documentSubstituted) {
                  await api.put(`/protocols/documents/${documentSubstituted}`, {
                    substituted: true,
                    substitute_cod: createdProtocol.data.cod,
                  });
                }
              } else {
                await api.post('/protocols/documents', {
                  protocol_id: createdProtocol.data.id,
                  deadline_date: formattedDeadline,
                  ...rest,
                  order: index,
                });
              }
            }
          );

          const viewersPromises = protocol.viewers.map(async viewer => {
            const { contact_id, relationship_id } = viewer;

            await api.post('/protocols/viewers', {
              contact_id,
              relationship_id,
              protocol_id: createdProtocol.data.id,
            });
          });

          await Promise.all(documentsPromises);
          await Promise.all(viewersPromises);
        }

        toast.success('Protocolo salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        formRef.current.setErrors({});
        setLoading(false);
        history.push('/protocol');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            if (error.path === 'viewers') {
              setRequiredViewerErrorMessage(error.message);
            }
            if (error.path === 'documents') {
              setRequiredDocumentErrorMessage(error.message);
            }
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar protocolo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setLoading(false);
      }
    },
    [company, id, protocol, user, documentsOptions]
  );

  const handleAddFile = useCallback(
    (index, file) => {
      setProtocol(
        produce(protocol, draft => {
          draft.documents[index].file = file.name;
          draft.documents[index].file_target = file;
        })
      );
    },
    [protocol]
  );

  const handleDeleteFile = useCallback(
    index => {
      setProtocol(
        produce(protocol, draft => {
          draft.documents[index].file = null;
          draft.documents[index].file_target = null;
        })
      );
    },
    [protocol]
  );

  const handleNewDocument = useCallback(
    index => {
      setProtocol(
        produce(protocol, draft => {
          const newDocument = {
            protocol_id: id,
            document_id: null,
            obs: '',
            file: '',
            competence: '',
            deadline_date: '',
            price: '',
          };

          if (index === null) {
            draft.documents.push(newDocument);
          } else {
            draft.documents.splice(index + 1, 0, newDocument);
          }
        })
      );
      setRequiredDocumentErrorMessage('');
    },
    [protocol, id]
  );

  const handleDeleteDocument = useCallback(
    index => {
      if (protocol.documents[index].id) {
        const { id: id_document } = protocol.documents[index];
        deleteDocuments.current.push(id_document);
      }

      setProtocol(
        produce(protocol, draft => {
          draft.documents.splice(index, 1);
        })
      );
    },
    [protocol, deleteDocuments]
  );

  const handleNewViewer = useCallback(
    index => {
      setProtocol(
        produce(protocol, draft => {
          const newViewer = {
            protocol_id: id,
            contact_id: null,
            relationship_id: null,
          };

          if (index === null) {
            draft.viewers.push(newViewer);
          } else {
            draft.viewers.splice(index + 1, 0, newViewer);
          }
        })
      );

      setRequiredViewerErrorMessage('');
    },
    [protocol, id]
  );

  const handleDeleteViewer = useCallback(
    index => {
      if (protocol.viewers[index].id) {
        const { id: id_viewer } = protocol.viewers[index];
        deleteViewers.current.push(id_viewer);
      }

      if (protocol.viewers[index].contact_id) {
        setViewersOptions(
          produce(viewersOptions, draft => {
            const pos = draft
              .map(e => e.value)
              .indexOf(protocol.viewers[index].contact_id);

            draft[pos].alreadyUsed = false;
          })
        );
      }

      setProtocol(
        produce(protocol, draft => {
          draft.viewers.splice(index, 1);
        })
      );
    },
    [protocol, deleteViewers, viewersOptions]
  );

  const handleChangeOrderDocuments = useCallback(
    (index, direction) => {
      setProtocol(
        produce(protocol, draft => {
          const aux = draft.documents[index];
          const lastPosition = draft.documents.length - 1;

          if (index === 0 && direction === 'up') {
            draft.documents[0] = draft.documents[lastPosition];
            draft.documents[lastPosition] = aux;
          } else if (index === lastPosition && direction === 'down') {
            const [firstDocument] = draft.documents;
            draft.documents[lastPosition] = firstDocument;
            draft.documents[0] = aux;
          } else if (direction === 'up') {
            draft.documents[index] = draft.documents[index - 1];
            draft.documents[index - 1] = aux;
          } else {
            draft.documents[index] = draft.documents[index + 1];
            draft.documents[index + 1] = aux;
          }
        })
      );
    },
    [protocol]
  );

  const resetForm = useCallback(() => {
    let aux;

    for (aux = 0; aux < protocol.documents.length; aux += 1) {
      handleDeleteDocument(aux);
    }

    for (aux = 0; aux < protocol.viewers.length; aux += 1) {
      handleDeleteViewer(aux);
    }

    formRef.current.reset();
    formRef.current.setErrors({});
    setRequiredDocumentErrorMessage('');
    setRequiredViewerErrorMessage('');
    setSelectedClient(null);
    setSelectedMethod(methodOptions[0]);
    setViewersOptions(options =>
      options.map(item => ({
        ...item,
        alreadyUsed: false,
      }))
    );
    setProtocol({
      id,
      cod: id ? protocol.cod : '',
      start_date: protocol.start_date,
      situation: 0,
      client_id: id ? protocol.client_id : null,
      obs: '',
      method: methodOptions[0].value,
      labor: false,
      tributary: false,
      accounting: false,
      financial: false,
      administration: false,
      documents: [],
      viewers: [],
    });
  }, [
    formRef,
    id,
    handleDeleteDocument,
    handleDeleteViewer,
    protocol,
    methodOptions,
  ]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/protocol')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const verifyDocument = useCallback(
    async (index, document_id, competence) => {
      setLoading(true);

      const response = await api.get('protocols/search/protocol-document', {
        params: {
          protocol_id: id,
          client_id: selectedClient,
          document_id,
          competence,
        },
      });

      if (response.data) {
        const { data } = response;

        const problem = {
          protocol_document_id: data.id,
          document:
            documentsOptions.find(doc => doc.value === document_id)?.label ||
            '',
          competence,
          protocol_cod: data.protocol.cod,
          problem_type: data.protocol.situation === 0 ? 0 : 1,
          index_document: index,
        };

        setDocumentProblem(problem);
        setModalIsOpen(true);
      }

      setLoading(false);
    },
    [id, selectedClient, documentsOptions]
  );

  const handleInputChange = useCallback(
    (event, name, index) => {
      const { value } = event.target;

      setProtocol(
        produce(protocol, draft => {
          draft.documents[index][name] = value;
        })
      );

      const document = protocol.documents[index];
      if (
        selectedMethod &&
        (selectedMethod.value === 0 || selectedMethod.value === 2)
      ) {
        if (
          name === 'competence' &&
          value.trim() !== '' &&
          value.indexOf('_') === -1 &&
          document.document_id !== null
        ) {
          verifyDocument(index, document.document_id, value);
        }
      }
    },
    [protocol, verifyDocument, selectedMethod]
  );

  const handleSelectChange = useCallback(
    (selectedOption, index) => {
      const { value } = selectedOption;

      setProtocol(
        produce(protocol, draft => {
          draft.documents[index].document_id = value;
        })
      );

      if (
        value &&
        selectedMethod &&
        (selectedMethod.value === 0 || selectedMethod.value === 2)
      ) {
        const { competence } = protocol.documents[index];
        if (competence.trim() !== '' && competence.indexOf('_') === -1) {
          verifyDocument(index, value, competence);
        }
      }
    },
    [protocol, verifyDocument, selectedMethod]
  );

  const handleSelectClient = useCallback(
    selected => {
      const { value, preference } = selected;

      if (value !== selectedClient) {
        setProtocol(oldProtocol => ({
          ...oldProtocol,
          viewers: [],
          documents: [
            {
              protocol_id: id,
              document_id: null,
              obs: '',
              file: '',
              competence: '',
              deadline_date: '',
              price: '',
            },
          ],
        }));

        setSelectedClient(value);
        setSelectedMethod(methodOptions[preference === 2 ? 1 : 0]);
      }
    },
    [methodOptions, selectedClient, id]
  );

  const handleSelectViewer = useCallback(
    (selectedOption, index) => {
      if (selectedOption) {
        const { value, relationship_id } = selectedOption;

        setProtocol(
          produce(protocol, draft => {
            if (index || index === 0) {
              draft.viewers[index].contact_id = value;
              draft.viewers[index].relationship_id = relationship_id;
            } else {
              draft.viewers.push({
                contact_id: value,
                relationship_id,
              });
            }
          })
        );

        setViewersOptions(
          produce(viewersOptions, draft => {
            const positionOfSelected = draft.map(e => e.value).indexOf(value);

            draft[positionOfSelected].alreadyUsed = true;

            // verify if is a select change
            if (protocol.viewers[index] && protocol.viewers[index].contact_id) {
              const positionOfLastSelected = draft
                .map(e => e.value)
                .indexOf(protocol.viewers[index].contact_id);

              draft[positionOfLastSelected].alreadyUsed = false;
            }
          })
        );
      }
    },
    [viewersOptions, protocol]
  );

  const handleCheckedDpt = useCallback(
    (checkbox, dpt) => {
      if (!selectedMethod || selectedMethod.value !== 3) {
        const { checked } = checkbox.target;

        if (checked) {
          const optionsOfDpt = viewersOptions.filter(
            option => option[dpt] === true && option.alreadyUsed === false
          );

          optionsOfDpt.forEach(option => handleSelectViewer(option));

          setProtocol(
            produce(protocol, draft => {
              optionsOfDpt.forEach(option => {
                draft.viewers.push({
                  contact_id: option.value,
                  relationship_id: option.relationship_id,
                });
              });
            })
          );

          setViewersOptions(
            produce(viewersOptions, draft => {
              const optionsValue = draft.map(e => e.value);

              optionsOfDpt.forEach(option => {
                const pos = optionsValue.indexOf(option.value);

                draft[pos].alreadyUsed = true;
              });
            })
          );
        } else {
          const optionsOfDpt = viewersOptions
            .filter(option => option[dpt] === true)
            .map(option => option.value);

          setProtocol(oldProtocol => ({
            ...oldProtocol,
            viewers: oldProtocol.viewers.filter(
              viewer => !optionsOfDpt.includes(viewer.contact_id)
            ),
          }));

          setViewersOptions(oldOptions =>
            oldOptions.map(oldOption => ({
              ...oldOption,
              alreadyUsed: !oldOption.alreadyUsed
                ? false
                : !optionsOfDpt.includes(oldOption.value),
            }))
          );
        }
      }
    },
    [viewersOptions, handleSelectViewer, protocol, selectedMethod]
  );

  const handleChangeMethod = useCallback(selected => {
    if (selected.value === 3) {
      setProtocol(oldProtocol => ({
        ...oldProtocol,
        viewers: [],
      }));

      setViewersOptions(options =>
        options.map(option => ({
          ...option,
          alreadyUsed: false,
        }))
      );
    }

    setSelectedMethod(selected);
  }, []);

  const confirmChangeMethod = useCallback(
    selected => {
      if (selectedClient && selected.label) {
        const client = clientsOptions.find(
          option => option.value === selectedClient
        );
        const preferenceMethodLabel =
          methodOptions[client.preference === 2 ? 1 : 0].label;

        if (
          selected.value !==
            methodOptions[client.preference === 2 ? 1 : 0].value &&
          selected.value !== 2 &&
          selected.value !== 3
        ) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <ConfirmWindow
                  onClick={() => handleChangeMethod(selected)}
                  onClose={onClose}
                  message={`A preferência de protocolo desse cliente é ${preferenceMethodLabel}. Deseja continuar como ${selected.label}?`}
                />
              );
            },
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        } else {
          handleChangeMethod(selected);
        }
      } else {
        handleChangeMethod(selected);
      }
    },
    [handleChangeMethod, clientsOptions, methodOptions, selectedClient]
  );

  const resolveProblem = useCallback(() => {
    const {
      problem_type,
      index_document,
      protocol_document_id,
      protocol_cod,
    } = documentProblem;

    if (problem_type === 0) {
      setProtocol(
        produce(protocol, draft => {
          draft.documents.splice(index_document, 1);
        })
      );
    } else {
      setProtocol(
        produce(protocol, draft => {
          draft.documents[
            index_document
          ].documentSubstituted = protocol_document_id;

          draft.documents[index_document].protocolSubstituted = protocol_cod;
        })
      );
    }
  }, [documentProblem, protocol]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaFolderOpen size={20} color="#44546a" />
            <h1>Protocolos</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {protocolLoading || !clientsOptions || !documentsOptions ? (
          <FormLoading />
        ) : (
          <Content className="content">
            {protocol && (
              <FormContainer
                ref={formRef}
                loading={loading ? 1 : 0}
                onSubmit={handleSubmit}
                initialData={initialData}
              >
                <ProtocolInfo>
                  <h4>PROTOCOLO</h4>
                  <section>
                    <Input
                      name="cod"
                      className="cod"
                      label="Cód."
                      type="text"
                      value={protocol.cod}
                      placeholder="(Automático)"
                      disabled
                    />
                    <DatePicker
                      name="start_date"
                      selected={protocol.start_date}
                      className="date"
                      label="Data"
                      readOnly
                    />
                    {id ? (
                      <Input
                        name="client_id"
                        className="client"
                        label="Cliente"
                        type="text"
                        value={
                          clientsOptions.find(
                            option => option.value === protocol.client_id
                          )?.label || ''
                        }
                        disabled
                      />
                    ) : (
                      <Select
                        name="client_id"
                        className="client"
                        label="Cliente"
                        type="text"
                        defaultValue={clientsOptions.find(
                          option => option.value === protocol.client_id
                        )}
                        options={clientsOptions}
                        onChange={handleSelectClient}
                        placeholder="Selecione um cliente"
                      />
                    )}
                    <Input
                      name="situation"
                      className="type"
                      label="Situação"
                      type="text"
                      value={
                        situationOptions.find(
                          option => option.value === protocol.situation
                        )?.label || ''
                      }
                      disabled
                    />
                  </section>
                  <section>
                    <TextArea
                      name="obs"
                      className="obs"
                      defaultValue={protocol.obs || ''}
                      label="Mensagem no corpo do email"
                      type="text"
                    />
                  </section>
                  <section>
                    <Select
                      name="method"
                      className="type"
                      label="Método"
                      type="text"
                      value={selectedMethod}
                      onChange={confirmChangeMethod}
                      options={methodOptions}
                      placeholder="Selecione um método"
                    />
                    <Checkbox
                      id="labor"
                      name="labor"
                      className="checkbox"
                      label="Trabalhista"
                      onChange={e => handleCheckedDpt(e, 'dpt_labour')}
                    />
                    <Checkbox
                      id="tributary"
                      name="tributary"
                      className="checkbox"
                      label="Tributário"
                      onChange={e => handleCheckedDpt(e, 'dpt_tax')}
                    />
                    <Checkbox
                      id="accounting"
                      name="accounting"
                      className="checkbox"
                      label="Contábil"
                      onChange={e => handleCheckedDpt(e, 'dpt_accounting')}
                    />
                    <Checkbox
                      id="financial"
                      name="financial"
                      className="checkbox"
                      label="Financeiro"
                      onChange={e => handleCheckedDpt(e, 'dpt_financial')}
                    />
                    <Checkbox
                      id="administration"
                      name="administration"
                      className="checkbox"
                      label="Administração"
                      onChange={e => handleCheckedDpt(e, 'dpt_admin')}
                    />
                  </section>

                  {(!selectedMethod || selectedMethod.value !== 3) && (
                    <>
                      <Title>
                        <h4 className="description">ENVIAR PARA</h4>
                        {viewersOptions.length > 0 && (
                          <button
                            type="button"
                            onClick={() => handleNewViewer(null)}
                          >
                            <FaPlus size={10} />
                          </button>
                        )}
                      </Title>

                      {requiredViewerErrorMessage && (
                        <span>{requiredViewerErrorMessage}</span>
                      )}

                      {viewersOptions.length > 0 &&
                        protocol.viewers.map((item, index) => (
                          <Scope path={`viewers[${index}]`} key={index}>
                            <section>
                              <Options>
                                <button
                                  type="button"
                                  onClick={() => handleNewViewer(index)}
                                >
                                  <FaPlus size={10} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDeleteViewer(index)}
                                >
                                  <FaMinus size={10} />
                                </button>
                              </Options>
                              <Select
                                name="contact_id"
                                className="viewer"
                                label="Usuário"
                                options={viewersOptions.filter(
                                  option => option.alreadyUsed === false
                                )}
                                value={
                                  viewersOptions.find(
                                    viewer => viewer.value === item.contact_id
                                  ) || ''
                                }
                                placeholder="Selecione um usuário"
                                onChange={e => handleSelectViewer(e, index)}
                              />
                            </section>
                          </Scope>
                        ))}
                    </>
                  )}

                  <Title>
                    <h4 className="description">DOCUMENTOS</h4>

                    {selectedClient && (
                      <>
                        <button
                          type="button"
                          onClick={() => handleNewDocument(null)}
                        >
                          <FaPlus size={10} />
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            setChangeOrderDocuments(prevState => !prevState)
                          }
                          className="order"
                        >
                          <FaExchangeAlt size={10} />
                        </button>
                      </>
                    )}
                  </Title>

                  {requiredDocumentErrorMessage && (
                    <span>{requiredDocumentErrorMessage}</span>
                  )}

                  {protocol.documents?.map((item, index) => (
                    <Scope path={`documents[${index}]`} key={index}>
                      <section>
                        {changeOrderDocuments ? (
                          <Options>
                            <button
                              type="button"
                              onClick={() =>
                                handleChangeOrderDocuments(index, 'up')
                              }
                            >
                              <FaAngleUp size={10} />
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleChangeOrderDocuments(index, 'down')
                              }
                            >
                              <FaAngleDown size={10} />
                            </button>
                          </Options>
                        ) : (
                          <Options>
                            <button
                              type="button"
                              onClick={() => handleNewDocument(index)}
                            >
                              <FaPlus size={10} />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteDocument(index)}
                            >
                              <FaMinus size={10} />
                            </button>
                          </Options>
                        )}
                        <Select
                          name="document_id"
                          className="document"
                          label="Documento"
                          value={
                            documentsOptions.find(
                              doc => doc.value === item.document_id
                            ) || null
                          }
                          options={documentsOptions}
                          placeholder="Seleciona um documento"
                          onChange={e => handleSelectChange(e, index)}
                        />
                        <InputMask
                          name="competence"
                          className="comp"
                          label="Comp."
                          type="text"
                          value={item.competence}
                          mask="99/9999"
                          onChange={e =>
                            handleInputChange(e, 'competence', index)
                          }
                        />
                        {selectedMethod.value === 3 && (
                          <InputMask
                            name="final_competence"
                            className="comp"
                            label="Comp. (fim)"
                            type="text"
                            value={item.final_competence}
                            mask="99/9999"
                            onChange={e =>
                              handleInputChange(e, 'final_competence', index)
                            }
                          />
                        )}
                        <InputMask
                          name="deadline_date"
                          className="deadline"
                          label="Vencimento"
                          type="text"
                          value={item.deadline_date}
                          mask="99/99/9999"
                          onChange={e =>
                            handleInputChange(e, 'deadline_date', index)
                          }
                        />
                        <Input
                          name="price"
                          className="price"
                          label="Valor"
                          type="number"
                          value={item.price}
                          onChange={e => handleInputChange(e, 'price', index)}
                        />

                        {item.file ? (
                          <div className="file">
                            <Delete>
                              <button
                                type="button"
                                onClick={() => handleDeleteFile(index)}
                              >
                                <FaTrash size={14} />
                              </button>
                            </Delete>
                            <Input
                              name="file"
                              className="file_name"
                              label="Arquivo"
                              type="text"
                              value={item.file}
                              disabled
                            />
                          </div>
                        ) : (
                          <UploadFile>
                            <File>
                              <label htmlFor={`file.doc.${index}`}>
                                <FaPaperclip size={14} color="#FCFCFC" />
                              </label>
                              <FileInput
                                id={`file.doc.${index}`}
                                name="file"
                                onChange={e => {
                                  handleAddFile(index, e.target.files[0]);
                                }}
                              />
                            </File>
                          </UploadFile>
                        )}
                      </section>

                      <section>
                        <TextArea
                          name="obs"
                          className="obs"
                          label="Observação"
                          type="text"
                          value={item.obs}
                          onChange={e => handleInputChange(e, 'obs', index)}
                        />
                      </section>
                    </Scope>
                  ))}
                </ProtocolInfo>
              </FormContainer>
            )}
          </Content>
        )}
      </Container>
      {loading && <Loading />}

      {modalIsOpen && (
        <AlreadyExistsDocuments
          setIsOpen={() => setModalIsOpen(false)}
          problem={documentProblem}
          resolve={resolveProblem}
        />
      )}
    </>
  );
};

export default Form;
