import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaRegCalendarAlt, FaExternalLinkAlt } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import redirectToOldPlatform from '~/util/redirectToOldPlatform';

import Profile from './Profile';
import SearchContact from './SearchContact';
import Notifications from './Notifications';
import Tv from './Tv';
import Dropdown from './Dropdown';
import PriceTable from './PriceTable';
import PermissionComponent from '../PermissionComponent';

import { Container, Content } from './styles';
import SearchPop from './SearchPop';
import Generator from './Generator';
import LinkProcess from './LinkProcess';
import XlsxProcessor from './Converte';
import Ticket from './Ticket';

export default function Header() {
  const { company, user } = useAuth();

  return (
    <Container>
      <Content>
        {user && company && (
          <>
            <nav>
              <Link to="/">
                <FaHome size={24} />
              </Link>
              <button
                type="button"
                onClick={() => redirectToOldPlatform(user, company.old_id, '')}
              >
                <FaExternalLinkAlt size="18" color="#fcfcfc" />
                <span>Plataforma antiga</span>
              </button>
              <SearchPop company={company} />
            </nav>

            <aside>
              <XlsxProcessor />

              <Dropdown />

              {company.hasSchedule && (
                <Link to={{ pathname: '/schedule', state: { id: null } }}>
                  <FaRegCalendarAlt size="18" color="#fcfcfc" />
                  <span>Agenda</span>
                </Link>
              )}

              <Tv />

              <Generator />

              <LinkProcess />

              <PermissionComponent level={3}>
                <PriceTable />
              </PermissionComponent>

              <SearchContact company={company.id} />

              {company.hasSchedule && <Ticket />}

              <Notifications />

              <Profile />
            </aside>
          </>
        )}
      </Content>
    </Container>
  );
}
