import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import * as Yup from 'yup';
import produce from 'immer';
import { Scope } from '@unform/core';

import {
  FaSave,
  FaBroom,
  FaTimes,
  FaClipboardList,
  FaPaperclip,
  FaTrash,
  FaPlus,
  FaMinus,
  FaInfoCircle,
} from 'react-icons/fa';

import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import {
  FormContainer,
  FormLoading,
  Input,
  FileInput,
  Select,
  Checkbox,
  DatePicker,
} from '~/components/Form';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import ShowPopModal from '~/components/Header/SearchPop/ShowPopModal';
import {
  Container,
  Header,
  Controls,
  Content,
  ProcessTypeInfo,
  Title,
  File,
  UploadFile,
  FileName,
  Delete,
  Options,
} from './styles';

const Form = () => {
  const { user, company, companyUsers } = useAuth();

  const { state } = useLocation();

  const formRef = useRef();
  const deleteViewers = useRef([]);

  const id = state?.id || null;
  const model_id = state?.model_id || null;

  const [formLoading, setFormLoading] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [optionsClientLoading, setOptionsClientLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [uploadFiles, setUploadFiles] = useState([]);

  const [initialData, setInitialData] = useState();

  const [modelsOptions, setModelsOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [responsibleOptions, setResponsibleOptions] = useState(null);

  const situationProcessOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Em andamento' },
      { value: 1, label: 'Concluído' },
    ];

    return options;
  }, []);

  const situationDocumentsOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Não recebido' },
      { value: 1, label: 'Pendente' },
      { value: 2, label: 'Recebido' },
      { value: 3, label: 'Desobrigado' },
    ];

    return options;
  }, []);

  const situationQuestionsOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Não respondida' },
      { value: 1, label: 'Pendente' },
      { value: 2, label: 'Respondida' },
      { value: 3, label: 'Desobrigado' },
    ];

    return options;
  }, []);

  const situationPhasesOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Não realizado' },
      { value: 1, label: 'Em andamento' },
      { value: 2, label: 'Pendente' },
      { value: 3, label: 'Realizado' },
      { value: 4, label: 'Desobrigado' },
    ];

    return options;
  }, []);

  const applyMask2 = (value, type) => {
    switch (type) {
      case 0: // Texto
        return value;
      case 1: // Inteiro
        return value.replace(/\D/g, '');
      case 2: // Decimal
        return value.replace(/[^0-9,]/g, '');
      case 3: // Moeda
        // Verifica se o valorInteiro é NaN (não é um número), o que pode acontecer se o valorNumerico for inválido.
        if (isNaN(parseInt(value.replace(/[^0-9]/g, ''), 10))) {
          return 'R$ 0,00'; // Ajusta para 0 se não for um número.
        }

        // Converte os centavos para reais ao dividir por 100 e formata o valor para a moeda desejada.
        return (
          parseInt(value.replace(/[^0-9]/g, ''), 10) / 100
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL', // Moeda definida como Real Brasileiro.
          minimumFractionDigits: 2, // Garante duas casas decimais.
        });

      default:
        return value;
    }
  };

  const handleChange = (e, type) => {
    e.target.value = applyMask2(e.target.value, type);
  };

  const [selectedClient, setSelectedClient] = useState(null);
  const [viewers, setViewers] = useState([]);
  const [viewersOptions, setViewersOptions] = useState([]);

  useEffect(() => {
    async function loadRelated() {
      if (selectedClient) {
        try {
          const response = await api.get(
            `/relationships/related/${selectedClient}`
          );

          const { data } = response;

          if (data.length > 0) {
            const options = [];

            data.forEach(item => {
              item.related_contacts.forEach(contact => {
                options.push({
                  value: contact.id,
                  relationship_id: item.info.id,
                  label: `${item.info.name} - ${contact.content}`,
                  dpt_accounting: item.dpt_accounting,
                  dpt_admin: item.dpt_admin,
                  dpt_financial: item.dpt_financial,
                  dpt_labour: item.dpt_labour,
                  dpt_tax: item.dpt_tax,
                  alreadyUsed: !!viewers.find(
                    viewer => viewer.contact_id === contact.id
                  ),
                });
              });
            });

            if (!id) {
              const defaultViewers = [];

              const dpt_accounting = formRef.current.getFieldValue(
                'accounting'
              );
              const dpt_financial = formRef.current.getFieldValue('financial');
              const dpt_labour = formRef.current.getFieldValue('labor');
              const dpt_admin = formRef.current.getFieldValue('administration');
              const dpt_tax = formRef.current.getFieldValue('trubutary');

              options.forEach((item, index) => {
                if (dpt_accounting && item.dpt_accounting) {
                  options[index].alreadyUsed = true;
                  defaultViewers.push({
                    contact_id: item.value,
                    relationship_id: item.relationship_id,
                  });
                } else if (dpt_labour && item.dpt_labour) {
                  options[index].alreadyUsed = true;
                  defaultViewers.push({
                    contact_id: item.value,
                    relationship_id: item.relationship_id,
                  });
                } else if (dpt_admin && item.dpt_admin) {
                  options[index].alreadyUsed = true;
                  defaultViewers.push({
                    contact_id: item.value,
                    relationship_id: item.relationship_id,
                  });
                } else if (dpt_financial && item.dpt_financial) {
                  options[index].alreadyUsed = true;
                  defaultViewers.push({
                    contact_id: item.value,
                    relationship_id: item.relationship_id,
                  });
                } else if (dpt_tax && item.dpt_tax) {
                  options[index].alreadyUsed = true;
                  defaultViewers.push({
                    contact_id: item.value,
                    relationship_id: item.relationship_id,
                  });
                }
              });

              setViewers(defaultViewers);
            }

            setViewersOptions(options);
          } else {
            toast.warn('Nenhuma pessoa relacionada foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } catch {
          toast.error('Falha ao buscar pessoas relacionadas.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadRelated();
    // eslint-disable-next-line
  }, [selectedClient]);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: null,
        label: 'Nenhum',
        active: true,
      });

      const [...rest] = options;
      const optionsForResponsible = [...rest];
      optionsForResponsible.pop();

      setResponsibleOptions(optionsForResponsible);
      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    async function loadModels() {
      if (user && company) {
        try {
          setOptionsLoading(true);
          const response = await api.get(`/process-models/simple/`, {
            params: {
              company_id: company.id,
              model_type: 0,
            },
          });

          if (response.data.length > 0) {
            setModelsOptions(
              response.data.map(item => ({
                value: item.id,
                label: item.title,
              }))
            );
          } else {
            toast.warn('Nenhum modelo foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            history.push({ pathname: '/process', state: { model_id } });
          }
          setOptionsLoading(false);
        } catch {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({ pathname: '/process', state: { model_id } });
        }
      }
    }

    async function loadClients() {
      if (user && company) {
        try {
          setOptionsClientLoading(true);
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: '',
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            setClientsOptions(
              response.data.map(item => ({
                value: item.id,
                label: item.name,
              }))
            );
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            history.push('/process-models');
          }
          setOptionsClientLoading(false);
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({ pathname: '/process', state: { model_id } });
        }
      }
    }

    loadModels();
    loadClients();
  }, [company, user, model_id]);

  const loadModel = useCallback(
    async model => {
      try {
        setFormLoading(true);

        const response = await api.get(`/process-models/new-process/${model}`);

        if (!response.data) {
          toast.error('Falha ao buscar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({ pathname: '/process', state: { model_id } });
        }

        response.data.documents.forEach(item => {
          item.situation = 0;
        });

        response.data.questions.forEach(item => {
          item.situation = 0;
        });

        response.data.phases.forEach(item => {
          item.situation = 0;
        });

        setUploadFiles({
          documents: response.data.documents.map(item => ({
            file: item.file,
            file_target: null,
          })),
        });

        response.data.start_date = new Date();
        response.data.situation = 0;
        response.data.client_id = initialData?.client_id || null;

        setInitialData(response.data);
        setFormLoading(false);
      } catch {
        toast.error('Falha ao buscar modelo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push({ pathname: '/process', state: { model_id } });
      }
    },

    // eslint-disable-next-line
    [model_id, initialData?.client_id]
  );

  const loadProcess = useCallback(async () => {
    if (user && company) {
      try {
        setFormLoading(true);

        const response = await api.get(`/processes/${id}`);
        const { data } = response;

        if (data) {
          data.questions = data.questions.map(questionItem => ({
            ...questionItem,
            conclusion_date: questionItem.conclusion_date,
            partner: questionItem.partner?.name || '',
          }));
          data.documents = data.documents.map(documentItem => ({
            ...documentItem,
            conclusion_date: documentItem.conclusion_date,
            partner: documentItem.partner?.name || '',
          }));
          data.phases = data.phases.map(phaseItem => ({
            ...phaseItem,
            conclusion_date: phaseItem.conclusion_date,
            update_at: phaseItem.update_at,
          }));

          setUploadFiles({
            documents: data.documents.map(item => ({
              file: item.file,
              file_target: null,
            })),
          });

          setViewers(data.viewers);
          setSelectedClient(data.client_id);
          setInitialData(data);
        } else {
          toast.warn('O processo não foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({ pathname: '/process', state: { model_id } });
        }

        setFormLoading(false);
      } catch {
        toast.error('Falha ao buscar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push({ pathname: '/process', state: { model_id } });
      }
    }
  }, [id, company, user, model_id]);

  useEffect(() => {
    if (id) {
      loadProcess();
    } else if (model_id) {
      loadModel(model_id);
    } else {
      toast.warn('Nenhum modelo selecionado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/process-models');
    }
  }, [id, model_id, loadModel, loadProcess]);

  const handleSubmit = useCallback(
    async data => {
      if (user && company) {
        setLoading(true);
        try {
          const schema = Yup.object().shape({
            client_id: Yup.number().typeError('O cliente é obrigatório.'),
            processModel_id: Yup.number().typeError('O modelo é obrigatório.'),
            user_id: Yup.number().typeError('O responsável é obrigatório'),
            start_date: Yup.string().typeError(
              'A data de início é obrigatória.'
            ),
            situation: Yup.number()
              .typeError('A situação é obrigatória.')
              .required('O modelo é obrigatório.'),
            labor: Yup.bool(),
            tributary: Yup.bool(),
            accounting: Yup.bool(),
            financial: Yup.bool(),
            administration: Yup.bool(),
            documents: Yup.array().of(
              Yup.object().shape({
                document: Yup.string().required('O documento é obrigatório.'),
                situation: Yup.number()
                  .typeError('A situação é obrigatória.')
                  .required('O modelo é obrigatório.'),
                obs: Yup.string().when('situation', {
                  is: value => value === 3,
                  then: Yup.string().when('required', {
                    is: value => value === 'true',
                    then: Yup.string(
                      'A observação deve ser um texto.'
                    ).required('A observação é obrigatória.'),
                    otherwise: Yup.string('A observação deve ser um texto.'),
                  }),
                  otherwise: Yup.string('A observação deve ser um texto.'),
                }),
                conclusion_date: Yup.string().typeError(
                  'A data de início é obrigatória.'
                ),
              })
            ),
            questions: Yup.array().of(
              Yup.object().shape({
                question: Yup.string().required('A pergunta é obrigatória.'),
                situation: Yup.number()
                  .typeError('A situação é obrigatória.')
                  .required('O modelo é obrigatório.'),
                answer: Yup.string()
                  .test(
                    'key_question',
                    'A resposta é obrigatória das perguntas chave.',
                    function validateAnswer(value) {
                      const { path } = this;
                      const question = parseInt(path.match(/\[(\d+)\]/)[1], 10);
                      if (
                        initialData.questions[question].key_question &&
                        !value
                      ) {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return this.createError({
                          path,
                          message:
                            'A resposta é obrigatória das perguntas chave.',
                        });
                      }
                      return true;
                    }
                  )
                  .when('situation', {
                    is: value => value === 2,
                    then: Yup.string('A resposta deve ser um texto.').test(
                      'is-required',
                      'A resposta é obrigatória.',
                      function validateAnswer(value) {
                        const { path } = this;
                        const question = parseInt(
                          path.match(/\[(\d+)\]/)[1],
                          10
                        );
                        if (
                          initialData.questions[question].required &&
                          !value
                        ) {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return this.createError({
                            path,
                            message: 'A resposta é obrigatória.',
                          });
                        }
                        if (
                          initialData.questions[question].key_question &&
                          !value
                        ) {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return this.createError({
                            path,
                            message:
                              'A resposta é obrigatória das perguntas chave.',
                          });
                        }
                        return true;
                      }
                    ),
                    otherwise: Yup.string('A resposta deve ser um texto.'),
                  }),
                conclusion_date: Yup.string()
                  .test(
                    'key_question',
                    'A data é obrigatória das perguntas chave.',
                    function validateAnswer(value) {
                      const { path } = this;
                      const question = parseInt(path.match(/\[(\d+)\]/)[1], 10);
                      if (
                        initialData.questions[question].key_question &&
                        !value
                      ) {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return this.createError({
                          path,
                          message: 'A data é obrigatória das perguntas chave.',
                        });
                      }
                      return true;
                    }
                  )
                  .typeError('Deve ser uma data válida.')
                  .nullable()
                  .when('situation', {
                    is: value => value === 2,
                    then: Yup.string()
                      .typeError('A data é obrigatória.')
                      .required('A data é obrigatória.'),
                    otherwise: Yup.string()
                      .typeError('Deve ser uma data válida.')
                      .nullable(),
                  }),
              })
            ),
            phases: Yup.array().of(
              Yup.object().shape({
                abbreviation: Yup.string().required(
                  'A abreviatura é obrigatória.'
                ),
                document: Yup.string().required('O documento é obrigatório.'),
                responsible_id: Yup.number()
                  .nullable(true)
                  .typeError('O responsável é obrigatório.'),
                notificate: Yup.bool(),
                situation: Yup.number()
                  .typeError('A situação é obrigatória.')
                  .required('O modelo é obrigatório.'),
                obs: Yup.string().when('situation', {
                  is: value => value === 4,
                  then: Yup.string().when('required', {
                    is: value => value === 'true',
                    then: Yup.string(
                      'A observação deve ser um texto.'
                    ).required('A observação é obrigatória.'),
                    otherwise: Yup.string('A observação deve ser um texto.'),
                  }),
                  otherwise: Yup.string('A observação deve ser um texto.'),
                }),
                extension_days: Yup.number()
                  .positive(
                    'O tempo de prorrogação deve ser um número positivo.'
                  )
                  .moreThan(
                    -1,
                    'O tempo de prorrogação tem que ser maior ou igual a 0'
                  )
                  .typeError('O tempo de prorrogação tem que ser maior que 0'),
                conclusion_date: Yup.string().typeError(
                  'A data de início é obrigatória.'
                ),
              })
            ),
            viewers: Yup.array().of(
              Yup.object().shape({
                contact_id: Yup.number().typeError('O usuário é obrigatória'),
              })
            ),
          });

          await schema.validate(data, { abortEarly: false });

          const {
            processModel_id,
            user_id,
            start_date,
            situation,
            labor,
            tributary,
            accounting,
            financial,
            administration,
          } = data;

          const client_id = id ? initialData.client_id : data.client_id;

          const notification = {
            model: modelsOptions.find(option => option.value === model_id)
              .label,
            sender_id: user.id,
            sender_name: user.short_name,
          };

          const documentsConcluded = data.documents
            ? data.documents.filter(
                item => item.situation === 2 || item.situation === 3
              ).length
            : 0;
          const questionsConcluded = data.questions
            ? data.questions.filter(
                item => item.situation === 2 || item.situation === 3
              ).length
            : 0;

          const dependencies_concluded =
            documentsConcluded + questionsConcluded;

          if (id) {
            let documents = [];
            if (data.documents) {
              const documentsPromises = data.documents.map(
                async ({ ...all }, index) => {
                  if (uploadFiles.documents[index].file_target) {
                    const formData = new FormData();

                    formData.append(
                      'file',
                      uploadFiles.documents[index].file_target
                    );

                    const fileResponse = await api.post(
                      'files/upload',
                      formData,
                      {
                        params: {
                          prefix: 'Process_Document',
                        },
                      }
                    );

                    const { blobName } = fileResponse.data;

                    documents.push({
                      id: initialData.documents[index].id,
                      file: uploadFiles.documents[index].file,
                      file_url: blobName,
                      ...all,
                    });
                  } else {
                    documents.push({
                      id: initialData.documents[index].id,
                      file: uploadFiles.documents[index].file,
                      file_url: uploadFiles.documents[index].file
                        ? initialData.documents[index].file_url
                        : null,
                      ...all,
                    });
                  }
                }
              );

              await Promise.all(documentsPromises);
            }
            documents = documents.length > 0 ? documents : null;

            let questions = [];
            if (data.questions) {
              questions = data.questions.map((question, index) => ({
                id: initialData.questions[index].id,
                ...question,
              }));
            }
            questions = questions.length > 0 ? questions : null;

            let phases = [];
            if (data.phases) {
              phases = data.phases.map((phase, index) => ({
                id: initialData.phases[index].id,
                ...phase,
              }));
            }
            phases = phases.length > 0 ? phases : null;

            await api.put(`/processes/${id}`, {
              client_id,
              processModel_id,
              user_id,
              view: initialData.user_id !== user_id ? 0 : 1,
              start_date,
              situation,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              notification,
              dependencies_concluded,
              phases,
              questions,
              documents,
            });

            const viewersPromises = viewers.map(async viewer => {
              const {
                id: id_viewer,
                contact_id,
                relationship_id,
                updated,
              } = viewer;

              if (!id_viewer) {
                await api.post('/processes/viewers', {
                  contact_id,
                  relationship_id,
                  process_id: id,
                });
              } else if (updated) {
                await api.put(`/processes/viewers/${id_viewer}`, {
                  contact_id,
                  relationship_id,
                });
              }
            });

            const deleteViewersPromises = deleteViewers.current.map(
              async id_viewer => {
                await api.delete(`/processes/viewers/${id_viewer}`);
              }
            );

            await Promise.all(viewersPromises);
            await Promise.all(deleteViewersPromises);
            await api.put(`/processes/prevision/${id}`);
          } else {
            let documents = [];
            if (data.documents) {
              const documentsPromises = data.documents.map(
                async ({ ...all }, index) => {
                  if (uploadFiles.documents[index].file_target) {
                    const formData = new FormData();

                    formData.append(
                      'file',
                      uploadFiles.documents[index].file_target
                    );

                    const fileResponse = await api.post(
                      'files/upload',
                      formData,
                      {
                        params: {
                          prefix: 'Process_Document',
                        },
                      }
                    );

                    const { blobName } = fileResponse.data;

                    documents.push({
                      ...all,
                      order: initialData.documents[index].order,
                      modelDocument_id: initialData.documents[index].id,
                      required: initialData.documents[index].required,
                      file: uploadFiles.documents[index].file,
                      file_url: blobName,
                    });
                  } else {
                    documents.push({
                      ...all,
                      order: initialData.documents[index].order,
                      modelDocument_id: initialData.documents[index].id,
                      required: initialData.documents[index].required,
                      file: uploadFiles.documents[index].file,
                      file_url: uploadFiles.documents[index].file
                        ? initialData.documents[index].file_url
                        : null,
                    });
                  }
                }
              );

              await Promise.all(documentsPromises);
            }
            documents = documents.length > 0 ? documents : null;

            let questions = [];
            if (data.questions) {
              questions = data.questions.map(({ ...all }, index) => ({
                ...all,
                modifiable_schedule:
                  initialData.questions[index]?.modifiable_schedule,
                order: initialData.questions[index].order,
                modelQuestion_id: initialData.questions[index].id,
                required: initialData.questions[index].required,
              }));
            }
            questions = questions.length > 0 ? questions : null;

            let phases = [];
            if (data.phases) {
              phases = data.phases.map(({ ...all }, index) => ({
                ...all,
                order: initialData.phases[index].order,
                modelPhase_id: initialData.phases[index].id,
                required: initialData.phases[index].required,
                type: initialData.phases[index].type,
                document_id: initialData.phases[index].document_id,
                document_required: initialData.phases[index].document_required,
                model_to_create: initialData.phases[index].model_to_create,
                related_pop: initialData.phases[index].related_pop,
                generate_protocol: initialData.phases[index].generate_protocol,
              }));
            }
            phases = phases.length > 0 ? phases : null;

            let viewersData = viewers.map(viewer => {
              const { contact_id, relationship_id } = viewer;

              return {
                contact_id,
                relationship_id,
              };
            });
            viewersData = viewersData.length > 0 ? viewersData : null;

            const response = await api.post('/processes', {
              client_id,
              company_id: company.id,
              processModel_id,
              user_id,
              start_date,
              situation,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              model_type: 0,
              notification,
              start_next_phase: initialData.start_next_phase,
              quant_dependencies: initialData.quant_dependencies,
              dependencies_concluded,
              phases,
              questions,
              documents,
              viewers: viewersData,
            });

            await api.put(`/processes/prevision/${response.data.id}`);
          }

          formRef.current.setErrors({});

          toast.success('Processo salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);

          history.push({ pathname: '/process', state: { model_id } });
        } catch (err) {
          setLoading(false);

          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);

            toast.error(
              'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else {
            toast.error('Falha ao salvar processo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    },
    [
      user,
      company,
      id,
      initialData,
      uploadFiles,
      model_id,
      modelsOptions,
      viewers,
    ]
  );

  const handleChangeModel = useCallback(
    selectedOption => {
      const { value } = selectedOption;
      loadModel(value);
    },
    [loadModel]
  );

  const handleChangeSituation = useCallback(
    (selectedOption, index, group) => {
      const { value } = selectedOption;

      setInitialData(
        produce(initialData, draft => {
          draft[group][index].situation = value;

          if (
            (group === 'documents' && value === 2) ||
            (group === 'phases' && value === 3)
          ) {
            draft[group][index].conclusion_date = new Date();
          } else {
            draft[group][index].conclusion_date = null;
          }
        })
      );
    },
    [initialData]
  );

  const handleAddFile = useCallback(
    (file, index, group) => {
      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[group][index].file_target = file;
          draft[group][index].file = file.name;
        })
      );
    },
    [uploadFiles]
  );

  const handleDeleteFile = useCallback(
    (index, group) => {
      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[group][index].file_target = null;
          draft[group][index].file = null;
        })
      );
    },
    [uploadFiles]
  );

  const handleAnswerQuestion = useCallback(
    ({ value }, index) => {
      const conclusion_date = formRef.current.getFieldValue(
        `questions[${index}].conclusion_date`
      );

      if (value && value.trim() !== '') {
        if (!conclusion_date) {
          formRef.current.setFieldValue(
            `questions[${index}].conclusion_date`,
            new Date()
          );
        }

        formRef.current.setFieldValue(
          `questions[${index}].situation`,
          situationQuestionsOptions[2]
        );
      } else {
        formRef.current.clearField(`questions[${index}].conclusion_date`);
        formRef.current.setFieldValue(
          `questions[${index}].situation`,
          situationQuestionsOptions[1]
        );
      }
    },
    [situationQuestionsOptions]
  );

  const handleNewViewer = useCallback(
    index => {
      setViewers(
        produce(viewers, draft => {
          const newViewer = {
            protocol_id: id,
            contact_id: null,
            relationship_id: null,
          };

          if (index === null) {
            draft.push(newViewer);
          } else {
            draft.splice(index + 1, 0, newViewer);
          }
        })
      );
    },
    [viewers, id]
  );

  const handleDeleteViewer = useCallback(
    index => {
      if (viewers[index].id) {
        const { id: id_viewer } = viewers[index];
        deleteViewers.current.push(id_viewer);
      }

      if (viewers[index].contact_id) {
        setViewersOptions(
          produce(viewersOptions, draft => {
            const pos = draft
              .map(e => e.value)
              .indexOf(viewers[index].contact_id);

            if (pos !== -1) {
              draft[pos].alreadyUsed = false;
            }
          })
        );
      }

      setViewers(
        produce(viewers, draft => {
          draft.splice(index, 1);
        })
      );
    },
    [viewers, deleteViewers, viewersOptions]
  );

  const handleSelectViewer = useCallback(
    (selectedOption, index) => {
      if (selectedOption) {
        const { value, relationship_id } = selectedOption;

        setViewers(
          produce(viewers, draft => {
            if (index || index === 0) {
              draft[index].contact_id = value;
              draft[index].relationship_id = relationship_id;

              if (draft[index].id) {
                draft[index].updated = true;
              }
            } else {
              draft.push({
                contact_id: value,
                relationship_id,
              });
            }
          })
        );

        setViewersOptions(
          produce(viewersOptions, draft => {
            const positionOfSelected = draft.map(e => e.value).indexOf(value);

            draft[positionOfSelected].alreadyUsed = true;

            // verify if is a select change
            if (viewers[index] && viewers[index].contact_id) {
              const positionOfLastSelected = draft
                .map(e => e.value)
                .indexOf(viewers[index].contact_id);

              if (positionOfLastSelected !== -1) {
                draft[positionOfLastSelected].alreadyUsed = false;
              }
            }
          })
        );
      }
    },
    [viewersOptions, viewers]
  );

  const handleCheckedDpt = useCallback(
    (checkbox, dpt) => {
      const { checked } = checkbox.target;

      if (checked) {
        const optionsOfDpt = viewersOptions.filter(
          option => option[dpt] === true && option.alreadyUsed === false
        );

        optionsOfDpt.forEach(option => handleSelectViewer(option));

        setViewers(
          produce(viewers, draft => {
            optionsOfDpt.forEach(option => {
              draft.push({
                contact_id: option.value,
                relationship_id: option.relationship_id,
              });
            });
          })
        );

        setViewersOptions(
          produce(viewersOptions, draft => {
            const optionsValue = draft.map(e => e.value);

            optionsOfDpt.forEach(option => {
              const pos = optionsValue.indexOf(option.value);

              draft[pos].alreadyUsed = true;
            });
          })
        );
      } else {
        const optionsOfDpt = viewersOptions
          .filter(option => option[dpt] === true)
          .map(option => option.value);

        setViewers(oldViewers =>
          oldViewers.filter(viewer => !optionsOfDpt.includes(viewer.contact_id))
        );

        setViewersOptions(oldOptions =>
          oldOptions.map(oldOption => ({
            ...oldOption,
            alreadyUsed: !oldOption.alreadyUsed
              ? false
              : !optionsOfDpt.includes(oldOption.value),
          }))
        );
      }
    },
    [viewersOptions, handleSelectViewer, viewers]
  );

  const resetForm = useCallback(() => {
    viewers.forEach(viewer => {
      if (viewer.id) {
        deleteViewers.current.push(viewer.id);
      }
    });
    setViewers([]);
    setViewersOptions([]);

    setSelectedClient(null);

    setViewersOptions(options =>
      options.map(item => ({
        ...item,
        alreadyUsed: false,
      }))
    );

    loadModel(model_id);
  }, [model_id, loadModel, viewers]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() =>
              history.push({ pathname: '/process', state: { model_id } })
            }
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [model_id]);

  const [popModalIsOpen, setPopModalIsOpen] = useState(false);
  const popForModal = useRef(null);

  async function handleOpenPopModal(data) {
    try {
      const response = await api.get(`/pop/${data.id}`);

      popForModal.current = response.data;
      setPopModalIsOpen(true);
    } catch (err) {
      toast.error('Falha ao buscar POP', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function handleOpenPopModalDocument(data) {
    try {
      const response = await api.get(`/pop/${data}`);

      popForModal.current = response.data;
      setPopModalIsOpen(true);
    } catch (err) {
      toast.error('Falha ao buscar POP', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaClipboardList size={20} color="#44546a" />
            <h1>Processos</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {formLoading || optionsLoading || optionsClientLoading ? (
          <FormLoading />
        ) : (
          <Content className="content">
            {initialData && responsibleOptions && (
              <FormContainer
                ref={formRef}
                initialData={initialData}
                loading={loading ? 1 : 0}
                onSubmit={handleSubmit}
              >
                <ProcessTypeInfo>
                  <h4>PROCESSO</h4>
                  <section>
                    {id ? (
                      <Input
                        name="clientName"
                        className="title"
                        label="Cliente"
                        type="text"
                        value={
                          clientsOptions.find(
                            option => option.value === initialData.client_id
                          )?.label || ''
                        }
                        disabled
                      />
                    ) : (
                      <Select
                        name="client_id"
                        className="title"
                        label="Cliente"
                        type="text"
                        options={clientsOptions}
                        onChange={e => {
                          setViewers([]);
                          setSelectedClient(e.value);
                        }}
                        placeholder="Selecione um cliente"
                      />
                    )}
                    {id ? (
                      <Input
                        name="processModel"
                        className="type"
                        label="Modelo"
                        type="text"
                        value={
                          modelsOptions.find(
                            option => option.value === model_id
                          ).label
                        }
                        disabled
                      />
                    ) : (
                      <Select
                        name="processModel_id"
                        className="type"
                        label="Modelo"
                        type="text"
                        options={modelsOptions}
                        onChange={handleChangeModel}
                        placeholder="Selecione um modelo"
                      />
                    )}

                    <Select
                      name="user_id"
                      className="user"
                      label="Responsável"
                      options={responsibleOptions}
                      placeholder="Selecione um responsável"
                    />

                    <DatePicker
                      name="start_date"
                      className="date"
                      label="Data de início"
                    />
                    <Select
                      name="situation"
                      className="type"
                      label="Situação"
                      type="text"
                      options={situationProcessOptions}
                      placeholder="Selecione um modelo"
                    />
                  </section>
                  <section className="sector">
                    <Checkbox
                      id="labor"
                      name="labor"
                      className="checkbox"
                      label="Trabalhista"
                      onChange={e => handleCheckedDpt(e, 'dpt_labour')}
                    />
                    <Checkbox
                      id="tributary"
                      name="tributary"
                      className="checkbox"
                      label="Tributário"
                      onChange={e => handleCheckedDpt(e, 'dpt_tax')}
                    />
                    <Checkbox
                      id="accounting"
                      name="accounting"
                      className="checkbox"
                      label="Contábil"
                      onChange={e => handleCheckedDpt(e, 'dpt_accounting')}
                    />
                    <Checkbox
                      id="financial"
                      name="financial"
                      className="checkbox"
                      label="Financeiro"
                      onChange={e => handleCheckedDpt(e, 'dpt_financial')}
                    />
                    <Checkbox
                      id="administration"
                      name="administration"
                      className="checkbox"
                      label="Administração"
                      onChange={e => handleCheckedDpt(e, 'dpt_admin')}
                    />
                  </section>

                  <Title>
                    <h4 className="description">ENVIAR PARA</h4>
                    {viewersOptions.length > 0 && (
                      <button
                        type="button"
                        onClick={() => handleNewViewer(null)}
                      >
                        <FaPlus size={10} />
                      </button>
                    )}
                  </Title>

                  {viewersOptions.length > 0 &&
                    viewers.map((item, index) => (
                      <Scope path={`viewers[${index}]`} key={index}>
                        <section>
                          <Options>
                            <button
                              type="button"
                              onClick={() => handleNewViewer(index)}
                            >
                              <FaPlus size={10} />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteViewer(index)}
                            >
                              <FaMinus size={10} />
                            </button>
                          </Options>
                          <Select
                            name="contact_id"
                            className="viewer"
                            label="Usuário"
                            options={viewersOptions.filter(
                              option => option.alreadyUsed === false
                            )}
                            value={
                              viewersOptions.find(
                                viewer => viewer.value === item.contact_id
                              ) || ''
                            }
                            placeholder="Selecione um usuário"
                            onChange={e => handleSelectViewer(e, index)}
                          />
                        </section>
                      </Scope>
                    ))}

                  {initialData.documents.length > 0 && (
                    <Title>
                      <h4 className="description">DOCUMENTAÇÃO NECESSÁRIA</h4>
                    </Title>
                  )}
                  {initialData.documents.map((item, index) => (
                    <section key={index}>
                      <Scope path={`documents[${index}]`}>
                        <Input
                          name="document"
                          className="document"
                          label="Documento"
                          type="text"
                        />
                        <Input name="required" type="hidden" />
                        {item.partner_id && (
                          <Input
                            name="partnerName"
                            className="document"
                            label="Sócio"
                            type="text"
                            value={item.partner}
                            disabled
                          />
                        )}
                        <Select
                          name="situation"
                          className="type"
                          label="Situação"
                          type="text"
                          options={situationDocumentsOptions}
                          onChange={e =>
                            handleChangeSituation(e, index, 'documents')
                          }
                          placeholder="Selecione uma situação"
                        />
                        {item.situation !== 2 ? (
                          <Input
                            name="obs"
                            className="obs"
                            label="Observação"
                            type="text"
                          />
                        ) : (
                          <DatePicker
                            name="conclusion_date"
                            className="date"
                            label="Recebido em"
                          />
                        )}
                        {item.pop ? (
                          <button
                            type="button"
                            onClick={() => handleOpenPopModal(item.pop)}
                            className="how-make"
                          >
                            <FaInfoCircle />
                            {/* <br /> */}
                            <span>Como fazer</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {item.model?.pop_id ? (
                          <button
                            type="button"
                            onClick={() =>
                              handleOpenPopModalDocument(item.model.pop_id)
                            }
                            className="how-make"
                          >
                            <FaInfoCircle />
                            {/* <br /> */}
                            <span>Como fazer</span>
                          </button>
                        ) : (
                          ''
                        )}
                        <UploadFile>
                          {uploadFiles.documents[index].file ? (
                            <>
                              <Delete>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeleteFile(index, 'documents')
                                  }
                                >
                                  <FaTrash size={14} />
                                </button>
                              </Delete>
                              <FileName>
                                <Input
                                  name="file_name"
                                  className="file"
                                  label="Arquivo"
                                  type="text"
                                  value={uploadFiles.documents[index].file}
                                  disabled
                                />
                              </FileName>
                            </>
                          ) : (
                            <File>
                              <label htmlFor={`file.doc.${index}`}>
                                <FaPaperclip size={14} color="#FCFCFC" />
                              </label>
                              <FileInput
                                id={`file.doc.${index}`}
                                name="file"
                                onChange={e =>
                                  handleAddFile(
                                    e.target.files[0],
                                    index,
                                    'documents'
                                  )
                                }
                              />
                            </File>
                          )}
                        </UploadFile>
                      </Scope>
                    </section>
                  ))}

                  {initialData.questions.length > 0 && (
                    <Title>
                      <h4 className="description">PERGUNTAS</h4>
                    </Title>
                  )}
                  {initialData.questions.map((item, index) => (
                    <section key={index}>
                      <Scope path={`questions[${index}]`}>
                        <Input
                          name="question"
                          className="document"
                          label="Pergunta"
                          type="text"
                        />
                        {item.partner_id && (
                          <Input
                            name="partnerName"
                            className="document"
                            label="Sócio"
                            type="text"
                            value={item.partner}
                            disabled
                          />
                        )}
                        <Select
                          name="situation"
                          className="type"
                          label="Situação"
                          type="text"
                          options={situationQuestionsOptions}
                          onChange={e =>
                            handleChangeSituation(e, index, 'questions')
                          }
                          placeholder="Selecione uma situação"
                        />

                        <Input
                          name="answer"
                          className="obs"
                          label="Resposta"
                          type="text"
                          onChange={e => handleChange(e, item.type_question)}
                          onBlur={e => {
                            handleAnswerQuestion(e.target.value, index);
                          }}
                        />
                        <DatePicker
                          name="conclusion_date"
                          className="date"
                          label="Recebido em"
                        />
                      </Scope>
                    </section>
                  ))}

                  {initialData.phases.length > 0 && (
                    <Title>
                      <h4 className="description">ETAPAS</h4>
                    </Title>
                  )}
                  {initialData.phases.map((item, index) => (
                    <section key={index}>
                      <Scope path={`phases[${index}]`}>
                        <Input
                          name="document"
                          className="document"
                          label="Documento"
                          type="text"
                        />
                        <Input name="required" type="hidden" />
                        <Input
                          name="abbreviation"
                          className="abreviate"
                          label="Abreviatura"
                          type="text"
                        />
                        <Select
                          name="author"
                          className="user"
                          label="Autor"
                          options={responsibleOptions}
                          placeholder="Selecione um autor"
                        />
                        <Select
                          name="responsible_id"
                          className="type"
                          label="Responsável"
                          type="text"
                          options={usersOptions}
                          placeholder="Selecione um responsável"
                        />
                        <Input
                          name="dead_line"
                          className="deadline"
                          label="Prazo Interno"
                          type="number"
                        />
                        <Input
                          name="dead_line_client"
                          className="deadline"
                          label="Prazo P/ Cliente"
                          type="number"
                        />
                        <Input
                          name="extension_days"
                          className="deadline"
                          label="Prorrogação"
                          type="number"
                        />
                        <Select
                          name="situation"
                          className="type"
                          label="Situação"
                          type="text"
                          options={situationPhasesOptions}
                          onChange={e =>
                            handleChangeSituation(e, index, 'phases')
                          }
                          placeholder="Selecione uma situação"
                        />
                        {item.situation !== 3 ? (
                          <Input
                            name="obs"
                            className="obs"
                            label="Observação"
                            type="text"
                          />
                        ) : (
                          <DatePicker
                            name="conclusion_date"
                            className="date phase"
                            label="Concluído em"
                          />
                        )}
                        <Checkbox
                          id={`notificate${index}`}
                          name="notificate"
                          className="checkbox"
                          label="Notificar"
                        />
                      </Scope>
                    </section>
                  ))}
                </ProcessTypeInfo>
              </FormContainer>
            )}
          </Content>
        )}
        {popModalIsOpen && (
          <ShowPopModal
            isOpen={popModalIsOpen}
            setIsOpen={() => setPopModalIsOpen(false)}
            pop={popForModal.current}
          />
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default Form;
