import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { StyledButton } from './styles';

const CustomToast = () => {
  const history = useHistory();
  return (
    <div className="custom-toast">
      <p>Será necessário criar um processo para esse novo cliente.</p>
      <StyledButton onClick={() => history.push('/process-models')}>
        Ir
      </StyledButton>
    </div>
  );
};

export default CustomToast;
