import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { useWindowSize } from '@react-hook/window-size';
import {
  FaSlidersH,
  FaTimes,
  FaSearch,
  FaEye,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSave,
  FaBroom,
  FaMinus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  FormContainer,
  FormLoading,
  Select,
  Input,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  ParameterInfo,
  Responsibles,
  Documents,
  ResponsibleList,
  Responsible,
} from './styles';

const ParameterModal = ({ isOpen, setIsOpen }) => {
  const { company, user, companyUser, companyUsers } = useAuth();

  const [width] = useWindowSize();

  const formRef = useRef(null);
  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isCreatingOrEditing, setIsCreatingOrEditing] = useState(false);

  const [parameters, setParameters] = useState([]);
  const [parameterDetails, setParameterDetails] = useState({});

  const [clientSearch, setClientSearch] = useState(() => {
    const clienteStorage = sessionStorage.getItem(
      '@Diretiva:labour-checklist:parameter:filter:client'
    );

    if (clienteStorage) {
      return clienteStorage;
    }

    return '';
  });

  const [totalParameters, setTotalParameters] = useState(0);
  const [parameterIndex, setParameterIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem(
      '@Diretiva:labour-checklist:parameter:page'
    );

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [parameterId, setParameterId] = useState(null);
  const [parameter, setParameter] = useState(null);
  const [clients, setClients] = useState([]);
  const [models, setModels] = useState([]);

  const [taxationType, setTaxationType] = useState(null);
  const [selectedStartPeriod, setSelectedStartPeriod] = useState(null);
  const [selectedEndPeriod, setSelectedEndPeriod] = useState(null);

  const [
    requiredResposnibleErrorMessage,
    setRequiredResponsibleErrorMessage,
  ] = useState('');

  const loadParameters = useCallback(async () => {
    if (isCreatingOrEditing === false && company) {
      try {
        setLoading(true);

        const response = await api.get('checklists-parameters', {
          params: {
            company_id: company.id,
            page: currentPage,
            client: clientSearch,
            department: 'dpt_labour',
          },
        });

        if (response.data.docs.length > 0) {
          const data = response.data.docs.map(parameterItem => {
            return {
              id: parameterItem.id,
              client: parameterItem.client,
              model_id: parameterItem.model_id,
              model: parameterItem.model,
              start_period: parameterItem.start_period,
              end_period: parameterItem.end_period,
              responsible: parameterItem.responsible.map(responsible => ({
                id: responsible.id,
                responsible_id: responsible.company_user
                  ? responsible.company_user.id
                  : null,
                name: responsible.company_user
                  ? responsible.company_user.user.name
                  : 'Não se aplica',
                start_period: responsible.start_period
                  ? format(parseISO(responsible.start_period), 'dd/MM/yyyy')
                  : '',
                end_period: responsible.end_period
                  ? format(parseISO(responsible.end_period), 'dd/MM/yyyy')
                  : '',
              })),
              active: parameterItem.active,
              obs: parameterItem.obs,
              actives_documents: parameterItem.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== false)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period
                    ? format(parseISO(parameterDoc.start_period), 'dd/MM/yyyy')
                    : '',
                  end_period: parameterDoc.end_period
                    ? format(parseISO(parameterDoc.end_period), 'dd/MM/yyyy')
                    : '',
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                }),
              inactives_documents: parameterItem.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== true)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period
                    ? format(parseISO(parameterDoc.start_period), 'dd/MM/yyyy')
                    : '',
                  end_period: parameterDoc.end_period
                    ? format(parseISO(parameterDoc.end_period), 'dd/MM/yyyy')
                    : '',
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                }),
            };
          });

          setParameters(data);
          setParameterDetails(data[0]);
          setTotalPages(response.data.pages);
          setTotalParameters(response.data.total);
        } else {
          setParameters([]);
          setParameterDetails([]);
          setTotalPages(1);
          setTotalParameters(0);
          toast.warn('Nenhum parâmetro foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar parâmetros.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    }
  }, [company, currentPage, clientSearch, isCreatingOrEditing]);

  useEffect(() => {
    loadParameters();
  }, [loadParameters]);

  useEffect(() => {
    async function loadParameter() {
      if (isCreatingOrEditing === true) {
        if (parameterId) {
          try {
            setLoading(true);

            const response = await api.get(
              `checklists-parameters/${parameterId}`
            );

            if (response.data) {
              const formattedParameter = response.data;

              const { start_period, end_period } = formattedParameter;

              formattedParameter.start_period = start_period;
              formattedParameter.end_period = end_period;

              formattedParameter.responsible =
                formattedParameter.responsible.length > 0 &&
                formattedParameter.responsible.map(responsible => ({
                  ...responsible,
                  start_period: responsible.start_period,
                  end_period: responsible.end_period,
                }));

              formattedParameter.actives_documents = formattedParameter.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== false)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period,
                  end_period: parameterDoc.end_period,
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                });

              formattedParameter.inactives_documents = formattedParameter.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== true)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period,
                  end_period: parameterDoc.end_period,
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                });

              setParameter(formattedParameter);
            }

            setLoading(false);
          } catch {
            toast.error('Falha ao buscar parâmetro.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            setLoading(false);
          }
        } else {
          setParameter({ responsible: [] });
        }
      }
    }

    loadParameter();
  }, [parameterId, isCreatingOrEditing]);

  useEffect(() => {
    async function loadClients() {
      if (isCreatingOrEditing === true && company) {
        setClientsLoading(true);

        const response = await api.get(
          `checklists-parameters/clients/${company.id}`,
          {
            params: {
              department: 'dpt_labour',
            },
          }
        );

        if (response.data) {
          const formattedClients = response.data.map(client => {
            return {
              value: client.id,
              label: client.name,
              start_period: client.start_day,
              end_period: client.end_period,
              taxation_type: client.tributary_profile.periods[0].taxation_type,
            };
          });

          setClients(formattedClients);
        }

        setClientsLoading(false);
      }
    }

    loadClients();
  }, [company, isCreatingOrEditing]);

  useEffect(() => {
    async function loadModels() {
      if (isCreatingOrEditing === true && company) {
        const response = await api.get('checklists-models', {
          params: {
            only_models: true,
            company_id: company.id,
            department: 'dpt_labour',
          },
        });

        const formattedModels = response.data.map(model => {
          return { value: model.id, label: model.model_name };
        });

        setModels(formattedModels);
      }
    }

    loadModels();
  }, [company, isCreatingOrEditing]);

  const usersOptions = useMemo(() => {
    if (companyUsers && companyUser && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .map(userItem => {
          return { value: userItem.id, label: userItem.short_name };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: companyUser.id,
        label: user.short_name,
      });

      return options;
    }

    return [];
  }, [companyUsers, user, companyUser]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            client: Yup.object().shape({
              name: Yup.string().required('A empresa é obrigatória.'),
            }),
            model: Yup.object().shape({
              name: Yup.string().required('O modelo é obrigatório.'),
            }),
            start_period: Yup.string().required(
              'A data de início é obrigatória.'
            ),
            responsible: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  company_user: Yup.object().shape({
                    id: Yup.string().required('O responsável é obrigatório.'),
                  }),
                  start_period: Yup.string()
                    .nullable()
                    .required('A data de início é obrigatória.'),
                  end_period: Yup.string().nullable(),
                })
              )
              .required('Pelo menos um responsável é obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (parameterId) {
            await api.put(`checklists-parameters/${parameterId}`, {
              obs: data.obs,
              active: data.active,
            });

            data.responsible = data.responsible.map(responsible => {
              return {
                id: responsible.id,
                parameter_id: parameterId,
                responsible_id: responsible.company_user.id,
                start_period: responsible.start_period,
                end_period: responsible.end_period || null,
              };
            });

            await api.put(`checklists-parameters/responsibles/${company.id}`, {
              responsibles: data.responsible,
            });

            const actives_documents =
              data?.actives_documents?.map(document => {
                return {
                  id: document.id,
                  situation: document.situation,
                };
              }) || [];

            const inactives_documents =
              data?.inactives_documents?.map(document => {
                return {
                  id: document.id,
                  situation: document.situation,
                };
              }) || [];

            const documents = actives_documents.concat(inactives_documents);

            await api.put(`checklists-parameters/documents/${company.id}`, {
              documents,
              company_id: company.id,
              department: 'dpt_labour',
            });
          } else {
            const parameterData = {
              company_id: company.id,
              client_id: data.client.name,
              model_id: data.model.name,
              start_period: data.start_period,
              end_period: data.end_period || null,
            };

            const createdParameter = await api.post(
              'checklists-parameters',
              parameterData
            );

            const { id: parameter_id } = createdParameter.data;

            const promises = data.responsible.map(async responsible => {
              const responsibleData = {
                id: responsible.id,
                parameter_id,
                responsible_id: responsible.company_user.id,
                start_period: responsible.start_period,
                end_period: responsible.end_period || null,
              };

              await api.post(
                'checklists-parameters/responsibles',
                responsibleData
              );

              let taxation_type;

              if (taxationType === 1) {
                taxation_type = 'taxation_mei';
              }
              if (taxationType === 3) {
                taxation_type = 'taxation_lucro_presumido';
              }
              if (taxationType === 4) {
                taxation_type = 'taxation_lucro_real';
              }
              if (taxationType === 5) {
                taxation_type = 'taxation_empregador_domestico';
              }
              if (taxationType === 6) {
                taxation_type = 'taxation_empregador_pf';
              }
              if (taxationType === 7) {
                taxation_type = 'taxation_simples_com_inscricao';
              }
              if (taxationType === 8) {
                taxation_type = 'taxation_simples_sem_inscricao';
              }
              if (taxationType === 9) {
                taxation_type = 'taxation_condominio';
              }
              if (taxationType === 10) {
                taxation_type = 'taxation_terceiro';
              }
              if (taxationType === 11) {
                taxation_type = 'taxation_mei_somente_declaracoes';
              }

              const documentsData = {
                parameter_id,
                model_id: data.model.name,
                taxation_type,
                department: 'dpt_labour',
                start_period: responsible.start_period,
                company_id: company.id,
                client_id: data.client.name,
              };

              await api.post('checklists-parameters/documents', documentsData);
            });

            await Promise.all(promises);
          }

          formRef.current.setErrors({});

          toast.success('Parâmetro salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setClientSearch('');
          setSaveLoading(false);
          setParameter(null);
          setIsCreatingOrEditing(false);
          setShowTable(true);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'responsible') {
                setRequiredResponsibleErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar parâmetro.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [company, taxationType, parameterId]
  );

  const handleFilter = useCallback(({ client }) => {
    setClientSearch(client);
    sessionStorage.setItem(
      '@Diretiva:labour-checklist:parameter:filter:client',
      client
    );

    setCurrentPage(1);
    sessionStorage.setItem('@Diretiva:labour-checklist:parameter:page', 1);
  }, []);

  const resetFilter = useCallback(() => {
    filterRef.current.reset();

    setCurrentPage(1);
    sessionStorage.setItem('@Diretiva:labour-checklist:parameter:page', 1);

    setClientSearch('');
    sessionStorage.removeItem(
      '@Diretiva:labour-checklist:parameter:filter:client'
    );
  }, []);

  const handleView = useCallback(() => {
    if (parameters.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, parameters]);

  const getDetails = useCallback(
    (id, index) => {
      const parameterItem = parameters.find(item => item.id === id);

      setParameterDetails(parameterItem);
      setParameterIndex(index);
      handleView();
    },
    [parameters, handleView]
  );

  const handlePrevItem = useCallback(() => {
    if (parameterIndex !== 0) {
      setParameterIndex(parameterIndex - 1);
      setParameterDetails(parameters[parameterIndex - 1]);
    } else {
      setParameterIndex(parameters.length - 1);
      setParameterDetails(parameters[parameters.length - 1]);
    }
  }, [parameterIndex, parameters]);

  const handleNextItem = useCallback(() => {
    if (parameterIndex !== parameters.length - 1) {
      setParameterIndex(parameterIndex + 1);
      setParameterDetails(parameters[parameterIndex + 1]);
    } else {
      setParameterIndex(0);
      setParameterDetails(parameters[0]);
    }
  }, [parameterIndex, parameters]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:labour-checklist:parameter:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem(
          '@Diretiva:labour-checklist:parameter:page',
          totalPages
        );
      } else {
        setCurrentPage(page);
        sessionStorage.setItem(
          '@Diretiva:labour-checklist:parameter:page',
          page
        );
      }
    },
    [totalPages]
  );

  const handleNew = useCallback(() => {
    setParameterId(null);
    setIsCreatingOrEditing(true);
  }, []);

  const handleEdit = useCallback(() => {
    setParameterId(parameterDetails.id);
    setIsCreatingOrEditing(true);
  }, [parameterDetails]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);

      await api.delete(`checklists-parameters/${parameterDetails.id}`);

      setClientSearch('');
      setDeleteLoading(false);
      handleView();
      loadParameters();

      toast.success('Parâmetro deletado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (err) {
      toast.error('Falha ao deletar parâmetro.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setDeleteLoading(false);
    }
  }, [handleView, loadParameters, parameterDetails]);

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleDelete} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    setParameterId(null);
    setParameter(null);
    setIsCreatingOrEditing(false);
    setShowTable(true);
  }, []);

  const confirmClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleClose} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleClose]);

  const handleAddNewResponsible = useCallback(() => {
    setParameter(
      produce(parameter, draft => {
        const blankResponsible = {
          id: uuid(),
          start_period: null,
          end_period: null,
        };

        if (draft.responsible.length > 0) {
          draft.responsible.push(blankResponsible);
        } else if (draft.responsible) {
          draft.responsible[0] = blankResponsible;
        } else {
          draft.responsible = [blankResponsible];
        }
      })
    );

    setRequiredResponsibleErrorMessage('');
  }, [parameter]);

  const handleRemoveResponsible = useCallback(
    async (responsibleId, index) => {
      setParameter(
        produce(parameter, draft => {
          delete draft.responsible[index];
        })
      );

      await api.delete(`checklists-parameters/responsibles/${responsibleId}`);
    },
    [parameter]
  );

  const confirmRemove = useCallback(
    (functionToExecute, responsibleId, index) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => functionToExecute(responsibleId, index)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    []
  );

  const handleChangeClient = useCallback(e => {
    if (e.start_period) {
      setSelectedStartPeriod(parseISO(e.start_period));

      formRef.current.setFieldError('start_period', '');
    }

    if (e.end_period) {
      setSelectedEndPeriod(parseISO(e.end_period));
    }

    setTaxationType(e.taxation_type);
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaSlidersH size={20} color="#44546a" />
            <h1>Parâmetros Trabalhistas</h1>
          </div>
          {!isCreatingOrEditing && (
            <aside>
              <button type="button" onClick={setIsOpen}>
                <FaTimes size={20} color="#44546a" />
              </button>
            </aside>
          )}
        </Header>
        <Controls>
          {!isCreatingOrEditing ? (
            <>
              <button type="button" onClick={handleView}>
                <FaEye size={15} color="#44546a" />
                <span>Visualização</span>
              </button>
              <button type="button" onClick={handleNew}>
                <FaPlus size={15} color="#44546a" />
                <span>Novo</span>
              </button>
              {showTable ? (
                <button type="button" onClick={resetFilter}>
                  <FaEraser size={15} color="#44546a" />
                  <span>Limpar filtros</span>
                </button>
              ) : (
                <>
                  <button type="button" onClick={handleEdit}>
                    <FaEdit size={15} color="#44546a" />
                    <span>Editar</span>
                  </button>
                  <button type="button" onClick={confirmDelete}>
                    <FaTrash size={15} color="#44546a" />
                    <span>Excluir</span>
                  </button>
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft size={15} color="#44546a" />
                    </button>
                    {totalParameters > 25 ? (
                      <span>{parameterIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {parameterIndex + 1} de {totalParameters}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight size={15} color="#44546a" />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <button type="submit" form="form">
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button" onClick={confirmResetForm}>
                <FaBroom size={15} color="#44546a" />
                <span>Limpar</span>
              </button>
              <button type="button" onClick={confirmClose}>
                <FaTimes size={15} color="#44546a" />
                <span>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        {showTable && !isCreatingOrEditing && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="client"
              className="client"
              label="Empresa"
              defaultValue={clientSearch}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}
        {!isCreatingOrEditing ? (
          <>
            {loading || !user || !companyUser || !company || !companyUsers ? (
              <TableLoading />
            ) : (
              <Content className="content">
                {showTable ? (
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="client">Empresa</th>
                        <th className="model">Modelo</th>
                        <th className="start_period">Início</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parameters.map((item, index) => (
                        <tr
                          key={item.id}
                          className="hover"
                          onClick={() => getDetails(item.id, index)}
                        >
                          <td className="client">{item.client.name}</td>
                          <td className="model">{item.model.name}</td>
                          <td className="start_period">{item.start_period}</td>
                        </tr>
                      ))}
                    </tbody>
                  </TableContainer>
                ) : (
                  <DetailsContainer>
                    <ParameterInfo>
                      <h4>PARÂMETRO</h4>
                      <section>
                        <div className="client">
                          <label>Empresa</label>
                          <input
                            name="client"
                            value={parameterDetails.client.name}
                            readOnly
                          />
                        </div>
                        <div className="model">
                          <label>Modelo</label>
                          <input
                            name="model"
                            value={parameterDetails.model.name}
                            readOnly
                          />
                        </div>
                        <div className="start_period">
                          <label>Início</label>
                          <input
                            name="start_period"
                            value={parameterDetails.start_period || ''}
                            readOnly
                          />
                        </div>
                        <div className="end_period">
                          <label>Fim</label>
                          <input
                            name="end_period"
                            value={parameterDetails.end_period || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="obs">
                          <label>Orientações</label>
                          <input
                            name="obs"
                            value={parameterDetails.obs || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    </ParameterInfo>
                    <Responsibles>
                      <h4>RESPONSÁVEIS</h4>
                      {parameterDetails &&
                        parameterDetails.responsible.map(responsible => (
                          <section key={responsible.id}>
                            <div className="responsible">
                              <label>Responsável</label>
                              <input
                                name="responsible"
                                value={responsible.name}
                                readOnly
                              />
                            </div>
                            <div className="start_period">
                              <label>Início</label>
                              <input
                                name="start_period"
                                value={responsible.start_period}
                                readOnly
                              />
                            </div>
                            <div className="end_period">
                              <label>Fim</label>
                              <input
                                name="end_period"
                                value={responsible.end_period}
                                readOnly
                              />
                            </div>
                          </section>
                        ))}
                    </Responsibles>
                    <Documents>
                      <h4>DOCUMENTOS ATIVOS</h4>
                      {parameterDetails &&
                        parameterDetails.actives_documents.length > 0 &&
                        parameterDetails.actives_documents.map(document => (
                          <React.Fragment key={`${document.id}`}>
                            <section>
                              <div className="document">
                                <label>Documento</label>
                                <input
                                  name="document"
                                  value={document.model_document.document_name}
                                  readOnly
                                />
                              </div>
                              <div className="obs">
                                <label>Obs</label>
                                <input
                                  name="obs"
                                  value={document.obs || ''}
                                  readOnly
                                />
                              </div>
                            </section>
                          </React.Fragment>
                        ))}
                    </Documents>
                    <Documents>
                      <h4>DOCUMENTOS INATIVOS</h4>
                      {parameterDetails &&
                        parameterDetails.inactives_documents.length > 0 &&
                        parameterDetails.inactives_documents.map(document => (
                          <React.Fragment key={document.id}>
                            <section>
                              <div className="document">
                                <label>Documento</label>
                                <input
                                  name="document"
                                  value={document.model_document.document_name}
                                  readOnly
                                />
                              </div>
                              <div className="obs">
                                <label>Obs</label>
                                <input
                                  name="obs"
                                  value={document.obs || ''}
                                  readOnly
                                />
                              </div>
                            </section>
                          </React.Fragment>
                        ))}
                    </Documents>
                  </DetailsContainer>
                )}
              </Content>
            )}
          </>
        ) : (
          <>
            {(loading ||
              clientsLoading ||
              !user ||
              !companyUser ||
              !company ||
              !companyUsers) && <FormLoading className="loading" />}
            {parameter !== null && (
              <Content className="content">
                <FormContainer
                  id="form"
                  ref={formRef}
                  loading={loading || clientsLoading ? 1 : 0}
                  onSubmit={handleSubmit}
                  initialData={parameter}
                >
                  <ParameterInfo>
                    <h4>PARÂMETRO</h4>
                    <section>
                      {parameterId ? (
                        <>
                          <Input
                            name="client.name"
                            className="client"
                            label="Empresa"
                            readOnly
                          />
                          <Input
                            name="model.name"
                            className="model"
                            label="Modelo"
                            readOnly
                          />
                          <DatePicker
                            name="start_period"
                            className="start_period"
                            label="Início"
                            readOnly
                          />
                          <DatePicker
                            name="end_period"
                            className="end_period"
                            label="Fim"
                            readOnly
                          />
                          <Checkbox
                            id="active"
                            name="active"
                            className="active"
                            label="Ativo"
                          />
                        </>
                      ) : (
                        <>
                          <Select
                            name="client.name"
                            className="client"
                            label="Empresa"
                            options={clients}
                            placeholder="Selecione uma empresa"
                            onChange={handleChangeClient}
                          />
                          <Select
                            name="model.name"
                            className="model"
                            label="Modelo"
                            options={models}
                            placeholder="Selecione um modelo"
                          />
                          <DatePicker
                            name="start_period"
                            className="start_period"
                            label="Início"
                            selected={selectedStartPeriod}
                            readOnly
                          />
                          <DatePicker
                            name="end_period"
                            className="end_period"
                            label="Fim"
                            selected={selectedEndPeriod}
                            readOnly
                          />
                        </>
                      )}
                    </section>
                    <section>
                      <Input name="obs" className="obs" label="Orientações" />
                    </section>
                  </ParameterInfo>
                  <ResponsibleList>
                    <header>
                      <h4>RESPONSÁVEIS</h4>
                      <button type="button" onClick={handleAddNewResponsible}>
                        <FaPlus size={10} />
                      </button>
                    </header>

                    {requiredResposnibleErrorMessage && (
                      <span>{requiredResposnibleErrorMessage}</span>
                    )}

                    {parameter.responsible &&
                      parameter.responsible.length > 0 &&
                      parameter.responsible.map((responsible, index) => (
                        <Responsible key={responsible.id}>
                          <button
                            type="button"
                            onClick={() =>
                              confirmRemove(
                                handleRemoveResponsible,
                                responsible.id,
                                index
                              )
                            }
                          >
                            <FaMinus size={10} />
                          </button>
                          <Scope path={`responsible[${index}]`}>
                            <section>
                              <Input
                                name="id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <Select
                                name="company_user.id"
                                className="responsible"
                                label="Responsável"
                                options={usersOptions}
                                placeholder="Selecione um responsável"
                              />
                              <DatePicker
                                name="start_period"
                                className="responsible_start_period"
                                label="Início"
                              />
                              <DatePicker
                                name="end_period"
                                className="responsible_end_period"
                                label="Fim"
                              />
                            </section>
                          </Scope>
                        </Responsible>
                      ))}
                  </ResponsibleList>
                  {parameterId && (
                    <>
                      <Documents>
                        <h4>DOCUMENTOS ATIVOS</h4>
                        {parameter &&
                          parameter.actives_documents.map((document, index) => (
                            <React.Fragment key={`${document.id}`}>
                              <Scope path={`actives_documents[${index}]`}>
                                <section>
                                  <Input
                                    name="id"
                                    type="text"
                                    className="hide"
                                    readOnly
                                  />
                                  <Checkbox
                                    id={`active-status${index}`}
                                    name="situation"
                                    className="status"
                                    label="Ativo"
                                  />
                                  <Input
                                    name="model_document.document_name"
                                    className="document"
                                    label="Documento"
                                    readOnly
                                  />
                                  <Input
                                    name="model_document.obs"
                                    className="obs"
                                    label="Obs"
                                  />
                                </section>
                              </Scope>
                            </React.Fragment>
                          ))}
                      </Documents>
                      <Documents>
                        <h4>DOCUMENTOS INATIVOS</h4>
                        {parameter &&
                          parameter.inactives_documents.map(
                            (document, index) => (
                              <React.Fragment key={`${document.id}`}>
                                <Scope path={`inactives_documents[${index}]`}>
                                  <section>
                                    <Input
                                      name="id"
                                      type="text"
                                      className="hide"
                                      readOnly
                                    />
                                    <Checkbox
                                      id={`unactive-status${index}`}
                                      name="situation"
                                      className="status"
                                      label="Ativo"
                                    />
                                    <Input
                                      name="model_document.document_name"
                                      className="document"
                                      label="Documento"
                                      readOnly
                                    />
                                    <Input
                                      name="model_document.obs"
                                      className="obs"
                                      label="Obs"
                                    />
                                  </section>
                                </Scope>
                              </React.Fragment>
                            )
                          )}
                      </Documents>
                    </>
                  )}
                </FormContainer>
              </Content>
            )}
          </>
        )}
        {!isCreatingOrEditing && (
          <Pagination
            width={width}
            loading={loading ? 1 : 0}
            currentPage={currentPage}
            pages={totalPages}
            totalDocs={totalParameters}
            handlePage={handlePage}
          />
        )}
      </Container>
      {(saveLoading || deleteLoading) && <Loading />}
    </Modal>
  );
};

export default ParameterModal;
