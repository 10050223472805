import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  format,
  parseISO,
  isToday,
  setMinutes,
  setHours,
  getYear,
  getMonth,
  getDate,
} from 'date-fns';
import { v4 as uuid } from 'uuid';
import produce from 'immer';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';
import {
  FaTimes,
  FaEye,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaCheck,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaDownload,
  FaHeadset,
  FaThumbsDown,
  FaThumbsUp,
  FaSave,
  FaBroom,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';
import fileDownload from 'js-file-download';
import {
  Select,
  Input,
  DatePicker,
  SelectWithFilterActiveCheckbox,
  FormLoading,
  FormContainer,
  InputMask,
  TextArea,
} from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import OptionWindow from '~/components/OptionWindow';
import { SendFeedback } from '~/components/SendFeedback';
import base64ToBlob from '~/util/converterBase64ToBLob';
import Modal from '~/components/Modal';
import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  TicketInfo,
  DateContainer,
  Feedback,
  FeedbackItem,
  TextAreaAsPlainText,
  Badge,
} from './styles';

const formatDescription = ticket => {
  try {
    const parsed_description = JSON.parse(ticket.description);

    let formatted_description = `${parsed_description.title} \n`;

    const { max_document_name_length } = parsed_description;

    if (
      Object.entries(parsed_description.data.not_yet_overdue_documents).length >
      0
    ) {
      formatted_description += '\n\tDocumentos prestes a vencer: \n\n';

      Object.entries(parsed_description.data.not_yet_overdue_documents).forEach(
        (e, index) => {
          formatted_description +=
            index === 0 ? `\t\t${e[0]}: \n\n` : `\n\t\t${e[0]}: \n\n`;
          e[1].forEach(document => {
            const document_name_length = document.document_name.length.toString();
            const number_of_spaces =
              max_document_name_length - document_name_length + 4;
            const spaces = ' '.repeat(number_of_spaces);

            formatted_description += `\t\t\t- ${document.document_name +
              spaces}Vencimento: ${document.formatted_due_date}\n`;
          });
        }
      );
    }

    if (Object.entries(parsed_description.data.overdue_documents).length > 0) {
      formatted_description += '\n\tDocumentos vencidos: \n\n';

      Object.entries(parsed_description.data.overdue_documents).forEach(
        (e, index) => {
          formatted_description +=
            index === 0 ? `\t\t${e[0]}: \n\n` : `\n\t\t${e[0]}: \n\n`;
          e[1].forEach(document => {
            const document_name_length = document.document_name.length.toString();
            const number_of_spaces =
              max_document_name_length - document_name_length + 4;
            const spaces = ' '.repeat(number_of_spaces);

            formatted_description += `\t\t\t- ${document.document_name +
              spaces}Vencimento: ${document.formatted_due_date}\n`;
          });
        }
      );
    }

    return formatted_description;
  } catch (e) {
    return ticket.description;
  }
};

const isJsonDescription = description => {
  try {
    JSON.parse(description);

    return true;
  } catch (e) {
    return false;
  }
};

const Ticket = () => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const [showTickets, setShowTickets] = useState(false);

  const formRef = useRef(null);
  const filterRef = useRef(null);
  const feedbackRef = useRef(null);

  const [ticketsLoading, setTicketsLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);
  const [copyFile, setCopyFile] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isCreatingOrEditing, setIsCreatingOrEditing] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);

  const [usersOptions, setUsersOptions] = useState([]);
  const [filterUser, setFilterUser] = useState(null);

  const [tickets, setTickets] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const [listOfFeedback, setListOfFeedback] = useState([]);
  const [hasOpenTickets, setHasOpenTickets] = useState(false);

  const [totalTickets, setTotalTickets] = useState(0);
  const [ticketIndex, setTicketIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [ticketsId, setTicketsId] = useState(null);
  const [ticket, setTicket] = useState(null);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());

  const [descriptionSearch, setDescriptionSearch] = useState(() => {
    const descriptionStorage = localStorage.getItem(
      '@Diretiva:ticket:filter:description'
    );

    if (descriptionStorage) {
      return descriptionStorage;
    }

    return '';
  });

  const [startPeriod, setStartPeriod] = useState(() => {
    const startPeriodStorage = localStorage.getItem(
      '@Diretiva:ticket:filter:start'
    );

    if (startPeriodStorage && startPeriodStorage !== 'null') {
      return new Date(startPeriodStorage);
    }

    return null;
  });
  const [startPeriodForDatePicker, setStartPeriodForDatePicker] = useState(
    () => {
      const startPeriodStorage = localStorage.getItem(
        '@Diretiva:ticket:filter:start'
      );

      if (startPeriodStorage && startPeriodStorage !== 'null') {
        return new Date(startPeriodStorage);
      }

      return null;
    }
  );
  const [endPeriod, setEndPeriod] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:ticket:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return null;
  });
  const [endPeriodForDatePicker, setEndPeriodForDatePicker] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:ticket:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return null;
  });

  const [sortConfig, setSortConfig] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedConclusion, setSelectedConclusion] = useState(() => {
    const conclusionStorage = localStorage.getItem(
      '@Diretiva:ticket:filter:conclusion'
    );

    if (conclusionStorage) {
      return JSON.parse(conclusionStorage);
    }

    return {
      value: 1,
      label: 'Em andamento',
    };
  });

  const hasTickets = useCallback(async () => {
    if (company && companyUser) {
      const response = await api.get('ticket/hasopentickets', {
        params: {
          company_id: company.id,
          recipient_id: user.id,
        },
      });
      if (response.data.hasOpenTickets !== undefined) {
        setHasOpenTickets(response.data.hasOpenTickets);
        return response.data.hasOpenTickets;
      }
    }

    return false;
  }, [company, companyUser, user]);

  useEffect(() => {
    hasTickets();
    const interval = setInterval(() => {
      hasTickets();
    }, 600000);
    return () => clearInterval(interval);
  }, [hasTickets]);

  const loadTickets = useCallback(async () => {
    if (!isCreatingOrEditing && company && selectedUser) {
      try {
        setTicketsLoading(true);

        const response = await api.get('ticket', {
          params: {
            company_id: company.id,
            page: currentPage,
            description: descriptionSearch,
            user: selectedUser.value,
            start_period: startPeriod,
            end_period: endPeriod,
            done: selectedConclusion.value,
            key: sortConfig.key,
            direction: sortConfig.direction,
          },
        });

        if (response.data.docs.length > 0) {
          const data = response.data.docs.map(item => {
            return {
              ...item,
              is_json_description: isJsonDescription(item.description),
              description: formatDescription(item),
              start_date: format(new Date(item.start_date), 'dd/MM/yyyy'),
              conclusion_date: item.conclusion_date
                ? format(
                    new Date(item.conclusion_date),
                    "dd/MM/yyyy 'às' HH:mm "
                  )
                : null,
              start_hour: format(new Date(item.start_date), 'HH:mm'),
              feedback: item.feedback.map(feedbackItem => ({
                ...feedbackItem,
                feedback_date_formatted: feedbackItem.feedback_date
                  ? format(
                      new Date(feedbackItem.feedback_date),
                      "dd/MM/yyyy 'às' HH:mm"
                    )
                  : null,
              })),
            };
          });

          setTickets(data);
          setTicketDetails(data[0]);
          setTotalPages(response.data.pages);
          setTotalTickets(response.data.total);
        } else {
          setTickets([]);
          setTicketDetails([]);
          setTotalPages(1);
          setTotalTickets(0);
          toast.warn('Nenhum atendimento foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        toast.error('Falha ao buscar atendimentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setTicketsLoading(false);
    }
  }, [
    isCreatingOrEditing,
    company,
    selectedUser,
    currentPage,
    descriptionSearch,
    startPeriod,
    endPeriod,
    selectedConclusion.value,
    sortConfig.key,
    sortConfig.direction,
  ]);

  useEffect(() => {
    if (showTickets) {
      loadTickets();
    }
  }, [loadTickets, showTickets]);

  useEffect(() => {
    async function loadTicket() {
      if (showTickets) {
        if (isCreatingOrEditing && company && companyUser) {
          if (ticketsId) {
            try {
              setTicketsLoading(true);

              const response = await api.get(`ticket/${ticketsId}`);

              if (response.data) {
                const { data } = response;

                const { start_date, conclusion_date } = data;

                data.start_date = start_date;
                data.conclusion_date = conclusion_date;
                data.start_hour = format(parseISO(start_date), 'HH:mm');
                data.recipient_id = data.recipient.id;
                setTicket(data);
              }

              setTicketsLoading(false);
            } catch (err) {
              toast.error('Falha ao buscar atendimento', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTicketsLoading(false);
            }
          } else {
            setTicket({
              type: 0,
            });
          }
        }
      }
    }

    loadTicket();
  }, [
    company,
    currentPage,
    descriptionSearch,
    selectedConclusion.value,
    sortConfig,
    companyUser,
    startPeriod,
    endPeriod,
    isCreatingOrEditing,
    ticketsId,
    showTickets,
  ]);

  const [typesOptions, setTypesOptions] = useState([]);

  const loadTypes = useCallback(async () => {
    if (isCreatingOrEditing && user && company) {
      try {
        const response = await api.get(`ticket/ticket-types`, {
          params: {
            company_id: company.id,
          },
        });

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.description.toUpperCase() < b.description.toUpperCase()) {
              return -1;
            }
            if (a.description.toUpperCase() > b.description.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          options = response.data
            .map(item => ({
              value: item.id,
              label: item.description,
            }))
            .filter(
              option => option.value !== undefined && option.value !== null
            );
        } else {
          toast.warn('Nenhum tipo de atendimento foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setTypesOptions(options);
        setTicketsLoading(false);
      } catch (error) {
        toast.error('Falha ao buscar tipos de atendimento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [user, company, isCreatingOrEditing]);

  useEffect(() => {
    if (showTickets) {
      loadTypes();
    }
  }, [loadTypes, showTickets]);

  const handleChangeType = selectedOption => {
    setTicket(prevTicket => ({
      ...prevTicket,
      type_id: selectedOption.value,
    }));
  };

  const [clientsOptions, setClientsOptions] = useState([]);

  const loadClients = useCallback(async () => {
    if (isCreatingOrEditing && user && company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          options = response.data
            .map(item => ({
              value: item.id,
              label: item.name,
            }))
            .filter(
              option => option.value !== undefined && option.value !== null
            );
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setClientsOptions(options);
        setTicketsLoading(false);
      } catch (error) {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [user, company, isCreatingOrEditing]);

  useEffect(() => {
    if (showTickets) {
      loadClients();
    }
  }, [loadClients, showTickets]);

  const handleChangeClient = selectedOption => {
    setTicket(prevTicket => ({
      ...prevTicket,
      client_id: selectedOption.value,
      contact_id: null,
    }));
  };

  const [contactsOptions, setContactsOptions] = useState([]);

  const loadContacts = useCallback(async () => {
    if (isCreatingOrEditing && user && company) {
      try {
        const response = await api.get(`relationships/contacts/${company.id}`);

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          options = response.data
            .map(item => ({
              value: item.id,
              label: item.name,
            }))
            .filter(
              option => option.value !== undefined && option.value !== null
            );
        } else {
          toast.warn('Nenhum contato foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setContactsOptions(options);
        setTicketsLoading(false);
      } catch (error) {
        toast.error('Falha ao buscar contatos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [user, company, isCreatingOrEditing]);

  useEffect(() => {
    if (showTickets) {
      loadContacts();
    }
  }, [loadContacts, showTickets]);

  const handleChangeContact = selectedOption => {
    setTicket(prevTicket => ({
      ...prevTicket,
      contact_id: selectedOption.value,
      client_id: null,
    }));
  };

  const handleChangeRecipient = useCallback(selectedOption => {
    setTicket(prevTicket => ({
      ...prevTicket,
      recipient_id: selectedOption.value,
    }));
  }, []);

  const handleRemoveFeedback = useCallback(
    index => {
      setListOfFeedback(
        produce(listOfFeedback, draft => {
          delete draft[index];
        })
      );
    },
    [listOfFeedback]
  );

  useEffect(() => {
    if (showTickets) {
      if (!showTable && !ticketsLoading) {
        const element = document.getElementById('feedback_message');

        if (element) {
          element.addEventListener('keydown', event => {
            if (event.ctrlKey && event.key === 'Enter') {
              feedbackRef.current.submitForm();
            }
          });
        }
      }
    }
  }, [showTable, ticketsLoading, showTickets]);

  useEffect(() => {
    if (showTickets) {
      if (companyUsers && user) {
        const options = companyUsers
          .filter(userItem => userItem.user_id !== user.id)
          .filter(userItem => userItem.user_id !== -1)
          .filter(userItem => userItem.active !== false)
          .map(userItem => {
            return {
              value: userItem.user_id,
              label: userItem.short_name,
              active: userItem.active,
            };
          });

        options.sort((a, b) => {
          if (a.label.toUpperCase() < b.label.toUpperCase()) {
            return -1;
          }
          if (a.label.toUpperCase() > b.label.toUpperCase()) {
            return 1;
          }
          return 0;
        });

        options.unshift({
          value: user.id,
          label: user.short_name,
          active: true,
        });

        options.push({
          value: '',
          label: 'Todos',
          active: true,
        });

        setUsersOptions(options);
      }
    }
  }, [companyUsers, user, showTickets]);

  useEffect(() => {
    if (showTickets) {
      if (user) {
        const userStorage = localStorage.getItem(
          '@Diretiva:ticket:filter:user'
        );

        if (userStorage) {
          setSelectedUser(JSON.parse(userStorage));
          setFilterUser(JSON.parse(userStorage));
        } else {
          setSelectedUser({
            value: user.id,
            label: user.short_name,
          });
          setFilterUser({
            value: user.id,
            label: user.short_name,
          });
        }
      }
    }
  }, [user, showTickets]);

  const conclusionOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Cancelado' },
      { value: 1, label: 'Em andamento' },
      { value: 2, label: 'Concluído' },
    ];
  }, []);

  const handleFormFeedback = useCallback(
    async data => {
      if (user) {
        try {
          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setListOfFeedback(
            produce(listOfFeedback, draft => {
              const newFeedback = {
                id: uuid(),
                feedback_date_formatted: format(
                  new Date(),
                  "dd/MM/yyyy 'às' HH:mm"
                ),
                short_name: user.short_name,
                type: data.file ? 1 : 0,
                file: data.file || null,
                content:
                  data.file && data.feedback_message === ''
                    ? data.file.name
                    : data.feedback_message,
                file_name: data.file ? data.file.name : null,
              };

              if (listOfFeedback.length > 0) {
                draft.unshift(newFeedback);
              } else {
                draft[0] = newFeedback;
              }
            })
          );

          setUploadFile([]);

          feedbackRef.current.reset();

          feedbackRef.current.setErrors({});
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar feedback.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setUploadFile([]);
        }
      }
    },
    [listOfFeedback, user]
  );

  useEffect(() => {
    if (showTickets) {
      if (ticket && !ticket.id) {
        const element = document.getElementById('feedback_message');

        if (element) {
          element.addEventListener('keydown', event => {
            if (event.ctrlKey && event.key === 'Enter') {
              handleFormFeedback({ feedback_message: event.target.value });
            }
          });
        }
      }
    }
  }, [ticket, handleFormFeedback, showTickets]);

  const handleView = useCallback(() => {
    if (tickets.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, tickets]);

  const getDetails = useCallback(
    async (id, index) => {
      const ticketItem = tickets.find(item => item.id === id);

      setTicketDetails(ticketItem);
      setTicketIndex(index);
      handleView();

      if (ticketItem.read === false) {
        await api.put(`ticket/read/${ticketItem.id}`);
      }
    },
    [tickets, handleView]
  );

  const handleFilter = useCallback(
    data => {
      setCurrentPage(1);

      setSelectedUser(usersOptions.find(option => option.value === data.user));
      localStorage.setItem(
        '@Diretiva:ticket:filter:user',
        JSON.stringify(usersOptions.find(option => option.value === data.user))
      );

      setDescriptionSearch(data.ticket);
      localStorage.setItem('@Diretiva:ticket:filter:description', data.ticket);

      setStartPeriod(data.start);
      setStartPeriodForDatePicker(data.start);
      localStorage.setItem('@Diretiva:ticket:filter:start', data.start);

      setEndPeriod(data.end);
      setEndPeriodForDatePicker(data.end);
      localStorage.setItem('@Diretiva:ticket:filter:end', data.end);

      setSelectedConclusion(
        conclusionOptions.find(option => option.value === data.conclusion)
      );
      localStorage.setItem(
        '@Diretiva:ticket:filter:conclusion',
        JSON.stringify(
          conclusionOptions.find(option => option.value === data.conclusion)
        )
      );
    },
    [usersOptions, conclusionOptions]
  );

  const resetFilter = useCallback(() => {
    if (user) {
      setCurrentPage(1);

      filterRef.current.setFieldValue('user', {
        value: user.id,
        label: user.short_name,
      });
      setSelectedUser({
        value: user.id,
        label: user.short_name,
      });
      setFilterUser({
        value: user.id,
        label: user.short_name,
      });
      localStorage.removeItem('@Diretiva:ticket:filter:user');

      filterRef.current.clearField('ticket');
      setDescriptionSearch('');
      localStorage.removeItem('@Diretiva:ticket:filter:description');

      filterRef.current.clearField('start');
      setStartPeriod(null);
      setStartPeriodForDatePicker(null);
      localStorage.removeItem('@Diretiva:ticket:filter:start');

      setEndPeriod(null);
      setEndPeriodForDatePicker(null);
      localStorage.removeItem('@Diretiva:ticket:filter:end');

      filterRef.current.setFieldValue('conclusion', {
        value: 1,
        label: 'Em andamento',
      });
      setSelectedConclusion({
        value: 1,
        label: 'Em andamento',
      });
      localStorage.removeItem('@Diretiva:ticket:filter:conclusion');
    }
  }, [filterRef, user]);

  const handleFilterUsersOptions = useCallback(
    value => {
      if (user && companyUsers) {
        if (value === true) {
          setUsersOptions(oldUsersOptions =>
            oldUsersOptions.filter(userItem => userItem.active !== false)
          );
        } else {
          const options = companyUsers
            .filter(userItem => userItem.user_id !== user.id)
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => {
              return {
                value: userItem.user_id,
                label: userItem.short_name,
                active: userItem.active,
              };
            });

          options.sort((a, b) => {
            if (a.label.toUpperCase() < b.label.toUpperCase()) {
              return -1;
            }
            if (a.label.toUpperCase() > b.label.toUpperCase()) {
              return 1;
            }
            return 0;
          });

          options.unshift({
            value: user.id,
            label: user.short_name,
            active: true,
          });

          options.push({
            value: '',
            label: 'Todos',
            active: true,
          });

          setUsersOptions(options);
        }
      }
    },
    [companyUsers, user]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handlePrevItem = useCallback(async () => {
    if (ticketIndex !== 0) {
      setTicketIndex(ticketIndex - 1);
      setTicketDetails(tickets[ticketIndex - 1]);
      if (tickets[ticketIndex - 1].read === false) {
        await api.put(`ticket/read/${tickets[ticketIndex - 1].id}`);
      }
    } else {
      setTicketIndex(tickets.length - 1);
      setTicketDetails(tickets[tickets.length - 1]);
      if (tickets[tickets.length - 1].read === false) {
        await api.put(`ticket/read/${tickets[tickets.length - 1].id}`);
      }
    }
  }, [ticketIndex, tickets]);

  const handleNextItem = useCallback(async () => {
    if (ticketIndex !== tickets.length - 1) {
      setTicketIndex(ticketIndex + 1);
      setTicketDetails(tickets[ticketIndex + 1]);
      if (tickets[ticketIndex + 1].read === false) {
        await api.put(`ticket/read/${tickets[ticketIndex + 1].id}`);
      }
    } else {
      setTicketIndex(0);
      setTicketDetails(tickets[0]);
      if (tickets[0].read === false) {
        await api.put(`ticket/read/${tickets[0].id}`);
      }
    }
  }, [ticketIndex, tickets]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`ticket/${id}`);

        setTickets(oldAppoinments =>
          oldAppoinments.filter(item => item.id !== id)
        );

        handleView();

        loadTickets();

        toast.success('Atendimento deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar atendimento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [handleView, loadTickets]
  );

  const confirmDelete = useCallback(() => {
    if (user && companyUser) {
      if (ticketDetails.sender?.id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do atendimento pode deletá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={() => handleDelete(ticketDetails.id)}
                onClose={onClose}
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [user, companyUser, ticketDetails, handleDelete]);

  const handleSubmit = useCallback(
    async data => {
      data.client_id = ticket?.client_id;
      data.type_id = ticket?.type_id;
      data.contact_id = ticket?.contact_id;
      if (company && user) {
        try {
          let blob = null;
          if (copyFile) {
            blob = base64ToBlob(copyFile);
          }
          setSaveLoading(true);

          if (copyFile) {
            data.file = {};
            data.file.name = 'feedback.png';
            data.file.type = 'image/png';
          }

          const schema = Yup.object().shape({
            description: Yup.string().required('A mensagem é obrigatória.'),
            start_date: Yup.string()
              .required('A data de início é obrigatória.')
              .nullable(),
            start_hour: Yup.string().required(
              'A hora de início é obrigatória.'
            ),
            client_id: Yup.mixed().when('contact_id', {
              is: value => !value || value === '',
              then: Yup.string().required(
                'É obrigatório informar cliente ou contato.'
              ),
              otherwise: Yup.mixed(),
            }),
            recipient_id: Yup.string().required('O recipiente é obrigatório.'),
            type_id: Yup.string().required('O tipo é obrigatório.'),
            request_sender: Yup.string().required(
              'O solicitante é obrigatório.'
            ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.conclusion_date = data.conclusion_date || null;

          const startHour = data.start_hour.split(':');

          data.start_date = setMinutes(
            setHours(
              new Date(
                getYear(data.start_date),
                getMonth(data.start_date),
                getDate(data.start_date)
              ),
              startHour[0]
            ),
            startHour[1]
          );

          if (ticket.id) {
            await api.put(`ticket/${ticket.id}`, data);
          } else {
            data.company_id = company.id;
            const createdTicket = await api.post(`ticket`, data);

            const { id: createdId } = createdTicket.data;

            if (listOfFeedback.length > 0) {
              const promises = listOfFeedback.map(async feedback => {
                const feedbackData = {
                  company_id: company.id,
                  ticket_id: createdId,
                  user_id: user.id,
                  content: feedback.content,
                  type: feedback.type,
                  file_name: feedback.file_name,
                };

                if (feedback.type === 1) {
                  const formData = new FormData();

                  if (!copyFile) {
                    formData.append('file', feedback.file);
                  } else {
                    formData.append('file', blob, 'feedback.png');
                    setCopyFile(null);
                  }

                  const fileResponse = await api.post(
                    'files/upload',
                    formData,
                    {
                      params: {
                        prefix: 'Ticket_Feedback',
                      },
                    }
                  );

                  const { blobName } = fileResponse.data;

                  feedbackData.link = blobName;

                  await api.post('ticket/feedback', feedbackData, {
                    params: {
                      isCreatingNewTicket: true,
                    },
                  });
                } else {
                  await api.post('ticket/feedback', feedbackData, {
                    params: {
                      isCreatingNewTicket: true,
                    },
                  });
                }
              });

              await Promise.all(promises);
            }
          }

          formRef.current.setErrors({});

          toast.success('Atendimento salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);
          setTicket(null);
          setIsCreatingOrEditing(false);
          setShowTable(true);
          loadTickets();
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar atendimento.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [ticket, company, user, copyFile, loadTickets, listOfFeedback]
  );

  const handleNew = useCallback(() => {
    setTicketIndex(0);
    setTicketsId(null);
    setTicket(null);
    setIsCreatingOrEditing(true);
    setShowTable(false);
  }, []);

  const handleEdit = useCallback(() => {
    setTicketsId(ticketDetails.id);
    setTicket(ticketDetails);
    if ((user, companyUser)) {
      if (ticketDetails.sender) {
        if (ticketDetails.sender?.id !== user?.id && companyUser.level < 9) {
          toast.warn('Somente o criador do atendimento pode editá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          setIsCreatingOrEditing(true);
        }
      } else if (companyUser.level === 9) {
        setIsCreatingOrEditing(true);
      } else {
        toast.warn('Somente o criador do atendimento pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [ticketDetails, user, companyUser]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => {
              setListOfFeedback([]);
              setTicketDetails({});
              setTicketsId(null);
              setTicket(null);
              setIsCreatingOrEditing(false);
              handleView();
              loadTickets();
            }}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleView, loadTickets]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleFeedback = async (data, feedbackReport) => {
    if (company && user) {
      let blob = null;
      if (copyFile) {
        blob = base64ToBlob(copyFile);
      }
      try {
        setFeedbackLoading(true);

        if (copyFile) {
          data.file = {};
          data.file.name = 'feedback.png';
          data.file.type = 'image/png';
        }

        const schema = Yup.object().shape({
          feedback_message: Yup.string().when('file', {
            is: value => value !== undefined,
            then: Yup.string(),
            otherwise: Yup.string().required('A mensagem é obrigatória'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const feedbackData = {
          company_id: company.id,
          ticket_id: ticketDetails.id,
          user_id: user.id,
          feedback: feedbackReport,
          content:
            data.file && data.feedback_message === ''
              ? data.file.name
              : data.feedback_message,
          type: data.file ? 1 : 0,
          file_name: data.file ? data.file.name : null,
        };

        if (data.file) {
          const formData = new FormData();

          if (!copyFile) {
            formData.append('file', data.file);
          } else {
            formData.append('file', blob, 'feedback.png');
            setCopyFile(null);
          }

          const fileResponse = await api.post('files/upload', formData, {
            params: {
              prefix: 'Ticket_Feedback',
            },
          });

          const { blobName } = fileResponse.data;

          feedbackData.link = blobName;
        }

        await api.post('ticket/feedback', feedbackData);

        const response = await api.get(
          `ticket/${ticketDetails.id ? ticketDetails.id : ticket.id}`
        );

        const ticketData = response.data;

        const { start_date, conclusion_date } = ticketData;

        ticketData.start_date = format(parseISO(start_date), 'dd/MM/yyyy');
        ticketData.conclusion_date = conclusion_date
          ? format(parseISO(conclusion_date), "dd/MM/yyyy 'às' HH:mm")
          : null;
        ticketData.start_hour = format(parseISO(start_date), 'HH:mm');

        const feedbackFormatted = ticketData.feedback.map(feedbackItem => ({
          ...feedbackItem,
          feedback_date_formatted: format(
            parseISO(feedbackItem.feedback_date),
            "dd/MM/yyyy 'às' HH:mm"
          ),
        }));

        ticketData.feedback = feedbackFormatted;

        setTickets(
          tickets.map(ticketItem =>
            ticketItem.id === data.id ? data : ticketItem
          )
        );

        setTicketDetails(ticketData);

        feedbackRef.current.clearField('feedback_message');

        feedbackRef.current.setErrors({});

        setUploadFile([]);
        setFeedbackLoading(false);

        toast.success('Feedback salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          feedbackRef.current.setErrors(errorMessages);
        } else {
          toast.error('Falha ao salvar feedback.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setUploadFile([]);
        setFeedbackLoading(false);
      }
    }
  };

  const alertFeedback = async data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OptionWindow
            yesClick={() => handleFeedback(data, true)}
            noClose={() => handleFeedback(data, false)}
            Close={() => onClose()}
            body="Deseja notificar o autor/destinatário?"
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const handleDeleteFeedback = useCallback(
    async (id, ticketId, userId, date) => {
      if (user) {
        if (user.id === userId) {
          if (isToday(parseISO(date))) {
            setDeleteFeedbackLoading(true);
            try {
              await api.delete(`ticket/feedback/${id}`);

              setTickets(
                tickets.map(item =>
                  item.id === ticketId
                    ? {
                        ...item,
                        feedback: item?.feedback?.filter(
                          feedback => feedback.id !== id
                        ),
                      }
                    : item
                )
              );

              setTicket(oldTicket => ({
                ...oldTicket,
                feedback: oldTicket?.feedback?.filter(
                  feedback => feedback.id !== id
                ),
              }));

              toast.success('Mensagem deletada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } catch {
              toast.error('Falha ao deletar mensagem.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } finally {
              setDeleteFeedbackLoading(false);
            }
          } else {
            toast.warn('Não é possivel deletar mensagens antigas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.warn('Somente o criador da mensagem pode deletá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user, tickets]
  );

  const confirmRemoveFeedback = useCallback(
    (id, ticketId, userId, date) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteFeedback(id, ticketId, userId, date)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteFeedback]
  );

  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  useEffect(() => {
    if (showTickets) {
      if (uploadFile.length !== 0) {
        feedbackRef.current.submitForm();
      }
    }
  }, [uploadFile, showTickets]);

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  const handlePrevUser = useCallback(() => {
    resetFilter();

    const index = usersOptions
      .map(e => {
        return e.value;
      })
      .indexOf(selectedUser.value);

    if (index === 0) {
      setSelectedUser(usersOptions[usersOptions.length - 1]);
      setFilterUser(usersOptions[usersOptions.length - 1]);
      localStorage.setItem(
        '@Diretiva:ticket:filter:user',
        JSON.stringify(usersOptions[usersOptions.length - 1])
      );
    } else {
      setSelectedUser(usersOptions[index - 1]);
      setFilterUser(usersOptions[index - 1]);
      localStorage.setItem(
        '@Diretiva:ticket:filter:user',
        JSON.stringify(usersOptions[index - 1])
      );
    }
  }, [usersOptions, selectedUser, resetFilter]);

  const handleNextUser = useCallback(() => {
    resetFilter();

    const index = usersOptions
      .map(e => {
        return e.value;
      })
      .indexOf(selectedUser.value);

    if (index + 1 === usersOptions.length) {
      setSelectedUser(usersOptions[0]);
      setFilterUser(usersOptions[0]);
      localStorage.setItem(
        '@Diretiva:ticket:filter:user',
        JSON.stringify(usersOptions[0])
      );
    } else {
      setSelectedUser(usersOptions[index + 1]);
      setFilterUser(usersOptions[index + 1]);
      localStorage.setItem(
        '@Diretiva:ticket:filter:user',
        JSON.stringify(usersOptions[index + 1])
      );
    }
  }, [usersOptions, selectedUser, resetFilter]);

  const handleDone = useCallback(
    async value => {
      setTickets(
        produce(tickets, draft => {
          draft.map(item => {
            if (item.id === ticketDetails.id) {
              item.done = value;

              item.conclusion_date = format(
                new Date(),
                "dd/MM/yyyy 'às' HH:mm "
              );
              return item;
            }
            return item;
          });
        })
      );
      setTicketDetails(
        produce(ticketDetails, draft => {
          draft.done = value;
          draft.conclusion_date = format(new Date(), "dd/MM/yyyy 'às' HH:mm ");
        })
      );

      await api.put(`ticket/done/${ticketDetails.id}`, {
        done: value,
      });
      hasTickets();
    },
    [tickets, ticketDetails, hasTickets]
  );

  const handleCheckCheckboxConclusion = async checked => {
    handleDone(checked);
  };

  const handleTickets = () => {
    setShowTickets(!showTickets);
    if (!showTickets) {
      setTicketsLoading(false);
      setFeedbackLoading(false);
      setDeleteLoading(false);
      setDeleteFeedbackLoading(false);
      setCopyFile(null);
      setSaveLoading(false);
      setShowTable(true);
      setIsCreatingOrEditing(false);
      setUploadFile([]);
      setUsersOptions([]);
      setFilterUser(null);
      setTickets([]);
      setTicketDetails({});
      setListOfFeedback([]);
      setTotalTickets(0);
      setTicketIndex(0);
      setCurrentPage(1);
      setTotalPages(1);
      setTicketsId(null);
      setTicket(null);
      setSelectedStartDate(new Date());
      setDescriptionSearch('');
      setStartPeriod(null);
      setStartPeriodForDatePicker(null);
      setEndPeriod(null);
      setEndPeriodForDatePicker(null);
      setSortConfig('');
      setSelectedUser(null);
      setSelectedConclusion({
        value: 1,
        label: 'Em andamento',
      });
      setTypesOptions([]);
      setClientsOptions([]);
      setContactsOptions([]);
    }
  };

  return (
    <>
      <Badge hasOpenTickets={hasOpenTickets} onClick={handleTickets}>
        <FaHeadset size={18} color="#fcfcfc" />
        <span>Atendimento</span>
      </Badge>
      {showTickets && (
        <Modal isOpen={showTickets} setIsOpen={handleTickets}>
          <Container>
            <Header>
              <div>
                <FaHeadset size={20} color="#fcfcfc" />
                <h1>Atendimento</h1>
              </div>
              <aside>
                <button type="button" onClick={handleTickets}>
                  <FaTimes size={20} color="#fcfcfc" />
                </button>
              </aside>
            </Header>
            <Controls>
              {!isCreatingOrEditing ? (
                <>
                  {showTable && (
                    <button type="button" onClick={handleView}>
                      <FaEye size={15} color="#44546a" />
                      <span>Visualização</span>
                    </button>
                  )}
                  {!showTable && (
                    <button
                      type="button"
                      onClick={() => {
                        setTicketDetails({});
                        setTicketsId(null);
                        setTicket(null);
                        setIsCreatingOrEditing(false);
                        handleView();
                        loadTickets();
                      }}
                    >
                      <FaEye size={15} color="#44546a" />
                      <span>Visualização</span>
                    </button>
                  )}
                  <button type="button" onClick={handleNew}>
                    <FaPlus />
                    <span>Novo</span>
                  </button>
                  {showTable ? (
                    <button type="button" onClick={resetFilter}>
                      <FaEraser size={15} color="#44546a" />
                      <span>Limpar filtros</span>
                    </button>
                  ) : (
                    <>
                      <button type="button" onClick={handleEdit}>
                        <FaEdit size={15} color="#44546a" />
                        <span>Editar</span>
                      </button>
                      <button type="button" onClick={confirmDelete}>
                        <FaTrash size={15} color="#44546a" />
                        <span>Excluir</span>
                      </button>
                      <div>
                        <button type="button" onClick={handlePrevItem}>
                          <FaChevronLeft size={15} color="#44546a" />
                        </button>
                        {totalTickets > 25 ? (
                          <span>{ticketIndex + 1} de 25</span>
                        ) : (
                          <span>
                            {ticketIndex + 1} de {totalTickets}
                          </span>
                        )}
                        <button type="button" onClick={handleNextItem}>
                          <FaChevronRight size={15} color="#44546a" />
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => formRef.current.submitForm()}
                  >
                    <FaSave size={15} color="#44546a" />
                    <span>Salvar</span>
                  </button>
                  <button type="button" onClick={confirmResetForm}>
                    <FaBroom size={15} color="#44546a" />
                    <span>Limpar</span>
                  </button>
                  <button type="button" onClick={handleClose}>
                    <FaTimes size={15} color="#44546a" />
                    <span>Fechar</span>
                  </button>
                </>
              )}
            </Controls>
            {showTable && filterUser && (
              <Filter ref={filterRef} onSubmit={handleFilter}>
                <button
                  className="arrowUser"
                  type="button"
                  onClick={handlePrevUser}
                >
                  <FaChevronLeft />
                </button>
                <SelectWithFilterActiveCheckbox
                  label="Usuário"
                  name="user"
                  className="user"
                  options={usersOptions}
                  value={filterUser}
                  handleFilter={handleFilterUsersOptions}
                  onChange={e => setFilterUser(e)}
                />
                <button
                  className="arrowUser"
                  type="button"
                  onClick={handleNextUser}
                >
                  <FaChevronRight />
                </button>

                <Input
                  name="ticket"
                  className="ticket"
                  label="Solicitação"
                  defaultValue={descriptionSearch}
                />

                <DateContainer className="period">
                  <label>Data de criação</label>
                  <div>
                    <DatePicker
                      name="start"
                      selected={startPeriodForDatePicker}
                      onChange={date => setStartPeriodForDatePicker(date)}
                    />
                    <span>até</span>
                    <DatePicker
                      name="end"
                      selected={endPeriodForDatePicker}
                      onChange={date => setEndPeriodForDatePicker(date)}
                    />
                  </div>
                </DateContainer>

                <Select
                  label="Status"
                  name="conclusion"
                  className="conclusion"
                  options={conclusionOptions}
                  defaultValue={selectedConclusion}
                />

                <button type="submit">
                  <FaSearch />
                </button>
              </Filter>
            )}
            {!isCreatingOrEditing ? (
              <>
                {ticketsLoading ||
                !user ||
                !companyUser ||
                !companyUsers ||
                !company ||
                !selectedUser ? (
                  <TableLoading />
                ) : (
                  <Content className="content">
                    {showTable ? (
                      <TableContainer>
                        <thead>
                          <tr>
                            <th className="thumb" />
                            <th
                              id="start_date"
                              className="start_date sort"
                              onClick={() => sort('start_date')}
                            >
                              <div>
                                Data
                                {sortConfig.key !== 'start_date' && <FaSort />}
                                {sortConfig.key === 'start_date' &&
                                  sortConfig.direction === 'asc' && (
                                    <FaSortUp />
                                  )}
                                {sortConfig.key === 'start_date' &&
                                  sortConfig.direction === 'desc' && (
                                    <FaSortDown />
                                  )}
                              </div>
                            </th>
                            <th className="sender">Autor</th>
                            <th className="recipient">Destinatário</th>
                            <th className="client">Cliente/Contato</th>
                            <th className="request_sender">Solicitante</th>
                            <th
                              id="description"
                              className="description sort"
                              onClick={() => sort('description')}
                            >
                              <div>
                                Solicitação
                                {sortConfig.key !== 'description' && <FaSort />}
                                {sortConfig.key === 'description' &&
                                  sortConfig.direction === 'asc' && (
                                    <FaSortUp />
                                  )}
                                {sortConfig.key === 'description' &&
                                  sortConfig.direction === 'desc' && (
                                    <FaSortDown />
                                  )}
                              </div>
                            </th>
                            <th className="read">Lido</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets.map((item, index) => (
                            <tr
                              key={String(item.id)}
                              onClick={() => getDetails(item.id, index)}
                              className="hover"
                            >
                              <td className="thumb">
                                {item.situation === 0 && (
                                  <FaThumbsDown size={12} color="#E53935" />
                                )}
                                {item.situation === 1 && (
                                  <FaThumbsDown size={12} color="#E53935" />
                                )}
                                {item.situation === 2 && (
                                  <FaThumbsUp size={12} color="#006229" />
                                )}
                              </td>

                              <td className="start_date">{item.start_date}</td>

                              <td className="sender">
                                {item.sender == null
                                  ? 'null'
                                  : item.sender.short_name.split(' ')[0]}
                              </td>
                              <td className="recipient">
                                {item.recipient?.short_name.split(' ')[0]}
                              </td>

                              <td className="client">
                                {item.client_id
                                  ? `Cliente: ${item.client?.nickname}`
                                  : `Contato: ${item.client?.name}`}
                              </td>
                              <td className="request_sender">
                                {item.request_sender}
                              </td>
                              <td className="description">
                                {item.description}
                              </td>
                              <td className="read">
                                {item.read && (
                                  <FaCheck size={12} color="#01579B" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </TableContainer>
                    ) : (
                      <DetailsContainer>
                        {ticketDetails && (
                          <>
                            <TicketInfo>
                              <h4>ATENDIMENTO</h4>
                              <section>
                                <div className="start_date">
                                  <label>Data</label>
                                  <input
                                    name="start_date"
                                    value={ticketDetails.start_date || ''}
                                    readOnly
                                  />
                                </div>

                                <div className="start_hour">
                                  <label>Hora</label>
                                  <input
                                    name="start_hour"
                                    value={ticketDetails.start_hour || ''}
                                    readOnly
                                  />
                                </div>

                                {ticketDetails.recipient &&
                                ticketDetails.recipient.id !== 2 ? (
                                  <div className="recipient">
                                    <label>Chamado para</label>
                                    <input
                                      name="recipient"
                                      value={
                                        ticketDetails.recipient.short_name || ''
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div className="recipient">
                                    <label>Chamado para</label>
                                    <input
                                      name="recipient"
                                      value="SISTEMA"
                                      readOnly
                                    />
                                  </div>
                                )}

                                <div className="type">
                                  <label>Tipo</label>
                                  <input
                                    name="type"
                                    value={
                                      ticketDetails.ticket_type?.description ||
                                      'Erro'
                                    }
                                    readOnly
                                  />
                                </div>
                              </section>
                              <section>
                                <div className="description">
                                  <label>Solicitação</label>
                                  {ticketDetails.is_json_description ? (
                                    <TextAreaAsPlainText>
                                      {ticketDetails.description || ''}
                                    </TextAreaAsPlainText>
                                  ) : (
                                    <TextareaAutosize
                                      name="description"
                                      value={ticketDetails.description || ''}
                                      readOnly
                                    />
                                  )}
                                </div>
                              </section>
                              <section>
                                {ticketDetails.sender !== null ? (
                                  <div className="sender">
                                    <label>Quem escreveu</label>
                                    <input
                                      name="sender"
                                      value={
                                        ticketDetails.sender?.short_name || ''
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div className="sender">
                                    <label>Quem escreveu</label>
                                    <input
                                      name="sender"
                                      value="SISTEMA"
                                      readOnly
                                    />
                                  </div>
                                )}

                                <div className="client">
                                  <label>Cliente</label>
                                  <input
                                    name="client_id"
                                    value={
                                      ticketDetails.client_id
                                        ? ticketDetails.client.name
                                        : ''
                                    }
                                    readOnly
                                  />
                                </div>

                                <div className="contact">
                                  <label>Contato</label>
                                  <input
                                    name="contact_id"
                                    value={
                                      ticketDetails.contact_id
                                        ? ticketDetails.client.name
                                        : ''
                                    }
                                    readOnly
                                  />
                                </div>

                                <div className="request_sender">
                                  <label>Solicitante</label>
                                  <TextareaAutosize
                                    name="request_sender"
                                    value={ticketDetails.request_sender || ''}
                                    readOnly
                                  />
                                </div>

                                {ticketDetails.situation === 0 && (
                                  <div className="situation">
                                    <label>Status</label>
                                    <input
                                      name="situation"
                                      value="Cancelado"
                                      readOnly
                                    />
                                  </div>
                                )}

                                {ticketDetails.situation === 1 && (
                                  <div className="situation">
                                    <label>Status</label>
                                    <input
                                      name="situation"
                                      value="Em Andamento"
                                      readOnly
                                    />
                                  </div>
                                )}

                                {ticketDetails.situation === 2 && (
                                  <div className="situation">
                                    <label>Status</label>
                                    <input
                                      name="situation"
                                      value="Concluído"
                                      readOnly
                                    />
                                  </div>
                                )}

                                {((ticketDetails.situation !== 0 &&
                                  ticketDetails.recipient_id === user.id &&
                                  ticketDetails.monitor === false) ||
                                  (companyUser.level === 9 &&
                                    ticketDetails.situation !== 0)) && (
                                  <div className="done checkbox">
                                    <label>Finalizar</label>
                                    <input
                                      name="done"
                                      checked={ticketDetails.done}
                                      type="checkbox"
                                      onChange={e =>
                                        handleCheckCheckboxConclusion(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                )}

                                {ticketDetails.done === true && (
                                  <div className="conclusion_date">
                                    <label>Concluído em</label>
                                    <input
                                      name="conclusion_date"
                                      value={
                                        ticketDetails.conclusion_date || ''
                                      }
                                      disabled
                                    />
                                  </div>
                                )}
                              </section>
                            </TicketInfo>
                            <Feedback
                              ref={feedbackRef}
                              onSubmit={alertFeedback}
                            >
                              <h4>FEEDBACK</h4>
                              <SendFeedback
                                uploadFile={uploadFile}
                                feedbackRef={feedbackRef}
                                copyFile={copyFile}
                                setCopyFile={setCopyFile}
                                setUploadFile={setUploadFile}
                              />
                              {ticketDetails.feedback?.length > 0 &&
                                ticketDetails.feedback.map(feedback => (
                                  <FeedbackItem key={feedback.id}>
                                    <div className="delete">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          confirmRemoveFeedback(
                                            feedback.id,
                                            feedback.ticket_id,
                                            feedback.user.id,
                                            feedback.feedback_date
                                          )
                                        }
                                      >
                                        <FaTrash size={14} />
                                      </button>
                                    </div>
                                    <div className="date">
                                      <label>Data/Hora</label>
                                      <input
                                        name="feedback_date_formatted"
                                        value={feedback.feedback_date_formatted}
                                        readOnly
                                      />
                                    </div>
                                    <div className="user">
                                      <label>Usuário</label>
                                      <input
                                        name="feedback_user"
                                        value={feedback.user.short_name}
                                        readOnly
                                      />
                                    </div>
                                    <div className="content">
                                      <label>Mensagem</label>
                                      <TextareaAutosize
                                        name="content"
                                        value={feedback.content}
                                        readOnly
                                        maxRows={4}
                                      />
                                    </div>
                                    {feedback.type === 1 && (
                                      <div className="file">
                                        <aside>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              openFile(feedback.link)
                                            }
                                            title="Visualizar arquivo"
                                          >
                                            <FaEye size={16} />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              downloadFile(
                                                feedback.link,
                                                feedback.file_name
                                              )
                                            }
                                            title="Baixar arquivo"
                                          >
                                            <FaDownload size={16} />
                                          </button>
                                        </aside>
                                        <div>
                                          <label>Arquivo</label>
                                          <input
                                            type="text"
                                            value={feedback.file_name}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </FeedbackItem>
                                ))}
                            </Feedback>
                          </>
                        )}
                      </DetailsContainer>
                    )}
                  </Content>
                )}
              </>
            ) : (
              <>
                {(ticketsLoading ||
                  !user ||
                  !company ||
                  !companyUser ||
                  !companyUsers) && <FormLoading className="loading" />}
                {ticket && (
                  <Content className="content">
                    <FormContainer
                      ref={formRef}
                      initialData={ticket}
                      onSubmit={handleSubmit}
                      loading={ticketsLoading ? 1 : 0}
                    >
                      <TicketInfo>
                        <h4>ATENDIMENTO</h4>
                        <section>
                          <DatePicker
                            name="start_date"
                            className="start_date"
                            label="Data de início"
                            selected={selectedStartDate}
                            onChange={date => setSelectedStartDate(date)}
                          />
                          {ticket.id ? (
                            <InputMask
                              name="start_hour"
                              className="start_hour"
                              type="text"
                              label="Hora"
                              mask="99:99"
                            />
                          ) : (
                            <InputMask
                              name="start_hour"
                              className="start_hour"
                              type="text"
                              label="Hora"
                              mask="99:99"
                              defaultValue="07:00"
                            />
                          )}
                          <Select
                            name="recipient_id"
                            className="recipient"
                            label="Chamado para"
                            type="text"
                            options={usersOptions}
                            onChange={handleChangeRecipient}
                            placeholder="Selecione um usuário"
                          />
                          <Select
                            name="type_id"
                            className="type"
                            label="Tipo"
                            options={typesOptions}
                            value={
                              typesOptions.find(
                                option => option.value === ticket?.type_id
                              ) || null
                            }
                            onChange={handleChangeType}
                            placeholder="Selecione um tipo"
                          />
                        </section>
                        <section>
                          <TextArea
                            name="description"
                            label="Solicitação"
                            className="description"
                          />
                        </section>
                        <section>
                          <Select
                            name="client_id"
                            className="client"
                            label="Cliente"
                            options={clientsOptions}
                            onChange={handleChangeClient}
                            value={
                              clientsOptions.find(
                                option => option.value === ticket?.client_id
                              ) || null
                            }
                            placeholder="Selecione um cliente"
                          />

                          <Select
                            name="contact_id"
                            className="contact"
                            label="Contato"
                            options={contactsOptions}
                            onChange={handleChangeContact}
                            value={
                              contactsOptions.find(
                                option => option.value === ticket?.contact_id
                              ) || null
                            }
                            placeholder="Selecione um contato"
                          />

                          <TextArea
                            name="request_sender"
                            label="Solicitante"
                            className="request_sender"
                          />
                        </section>
                      </TicketInfo>
                    </FormContainer>
                    {!ticket.id && (
                      <Feedback ref={feedbackRef} onSubmit={handleFormFeedback}>
                        <h4>FEEDBACK</h4>
                        <SendFeedback
                          uploadFile={uploadFile}
                          feedbackRef={feedbackRef}
                          copyFile={copyFile}
                          setCopyFile={setCopyFile}
                          setUploadFile={setUploadFile}
                        />
                        {listOfFeedback.length > 0 &&
                          listOfFeedback.map((feedback, index) => (
                            <FeedbackItem key={`${feedback.id}${index}`}>
                              <button
                                type="button"
                                onClick={() => handleRemoveFeedback(index)}
                              >
                                <FaMinus size={10} />
                              </button>

                              <div className="date">
                                <label>Data/Hora</label>
                                <input
                                  name="feedback_date_formatted"
                                  value={feedback.feedback_date_formatted}
                                  readOnly
                                />
                              </div>
                              <div className="user">
                                <label>Usuário</label>
                                <input
                                  name="user"
                                  value={feedback.short_name}
                                  readOnly
                                />
                              </div>
                              {feedback.type === 0 ? (
                                <div className="content">
                                  <label>Mensagem</label>
                                  <textarea
                                    name="content"
                                    value={feedback.content}
                                    readOnly
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="content">
                                    <label>Mensagem</label>
                                    <input
                                      name="content"
                                      value={feedback.content}
                                      readOnly
                                    />
                                  </div>
                                  <div className="file">
                                    <label>Arquivo</label>
                                    <input
                                      type="text"
                                      value={feedback.file_name}
                                      readOnly
                                    />
                                  </div>
                                </>
                              )}
                            </FeedbackItem>
                          ))}
                      </Feedback>
                    )}
                  </Content>
                )}
              </>
            )}
            {!isCreatingOrEditing && (
              <Pagination
                loading={ticketsLoading ? 1 : 0}
                currentPage={currentPage}
                pages={totalPages}
                totalDocs={totalTickets}
                handlePage={handlePage}
              />
            )}
          </Container>
          {(deleteLoading ||
            feedbackLoading ||
            deleteFeedbackLoading ||
            saveLoading) && <Loading />}
        </Modal>
      )}
    </>
  );
};

export default Ticket;
