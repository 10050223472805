import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { Scope } from '@unform/core';
import { format, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import produce from 'immer';
import * as Yup from 'yup';
import {
  FaUserFriends,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Select,
  Input,
  InputMask,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Content,
  BasicInfo,
  Departments,
  WorkTime,
  WorkTimetable,
  AccessTimetable,
  Time,
} from './styles';

const Form = () => {
  const { company, companyUsers } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchCpfLoading, setSearchCpfLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [usersOptions, setUsersOptions] = useState(null);
  const [isUserActive, setIsUserActive] = useState(true);

  const [
    requiredDepartmentErrorMessage,
    setRequiredDepartmentErrorMessage,
  ] = useState('');
  const [
    requiredWorkTimeErrorMessage,
    setRequiredWorkTimeErrorMessage,
  ] = useState('');
  const [
    requiredAccessTimeErrorMessage,
    setRequiredAccessTimeErrorMessage,
  ] = useState('');

  useEffect(() => {
    async function loadUser() {
      if (id) {
        try {
          setLoading(true);

          const { data } = await api.get(`company-users/${id}`);

          if (data) {
            const formattedData = {
              ...data,
              birthday: data.birthday,
              work_time: data.work_time
                ? {
                    ...data.work_time,
                    start: data.work_time.start,
                    stop: data.work_time.stop,
                    work_timetable: data.work_time?.work_timetable
                      .map(time => ({
                        ...time,
                        in_hour: format(parseISO(time.in_hour), 'HH:mm'),
                        out_hour: format(parseISO(time.out_hour), 'HH:mm'),
                      }))
                      .sort((a, b) => {
                        if (a.day_number < b.day_number) {
                          return -1;
                        }
                        if (a.day_number > b.day_number) {
                          return 1;
                        }
                        return 0;
                      }),
                  }
                : null,
              access_timetable: data.access_timetable
                ?.map(time => ({
                  ...time,
                  start: format(parseISO(time.start), 'HH:mm'),
                  end: format(parseISO(time.end), 'HH:mm'),
                }))
                .sort((a, b) => {
                  if (a.day_number < b.day_number) {
                    return -1;
                  }
                  if (a.day_number > b.day_number) {
                    return 1;
                  }
                  return 0;
                }),
            };

            setUser(formattedData);
            setIsUserActive(data.active);
          }
        } catch (err) {
          toast.error('Falha ao buscar usuário.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } else {
        setUser({
          active: true,
          work_time: {
            work_timetable: [
              {
                id: uuid(),
                day: '',
                in_hour: '',
                out_hour: '',
              },
            ],
          },
          access_timetable: [
            {
              id: uuid(),
              day: '',
              start: '',
              stop: '',
            },
          ],
        });
      }
    }

    loadUser();
  }, [id]);

  useEffect(() => {
    if (companyUsers) {
      const options = companyUsers
        .filter(userItem => userItem.id !== id)
        .filter(userItem => userItem.user_id !== 36)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      setUsersOptions(options);
    }
  }, [companyUsers, id]);

  const levelOptions = useMemo(() => {
    return [
      { value: 0, label: 'Bloqueado' },
      { value: 1, label: 'Usuário externo' },
      { value: 2, label: 'Usuário padrão' },
      { value: 3, label: 'Usuário Avançado' },
      { value: 4, label: 'Coordenador' },
      { value: 5, label: 'Supervisor' },
      { value: 6, label: 'Gestor' },
      { value: 9, label: 'Administrador' },
    ];
  }, []);

  const dayOptions = useMemo(() => {
    return [
      { value: 'Mo', label: 'Segunda' },
      { value: 'Tu', label: 'Terça' },
      { value: 'We', label: 'Quarta' },
      { value: 'Th', label: 'Quinta' },
      { value: 'Fr', label: 'Sexta' },
      { value: 'Sa', label: 'Sábado' },
      { value: 'Su', label: 'Domingo' },
    ];
  }, []);

  const handleClearFormErrors = useCallback(() => {
    formRef.current.setErrors({});
    setRequiredDepartmentErrorMessage('');
    setRequiredAccessTimeErrorMessage('');
    setRequiredWorkTimeErrorMessage('');
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          handleClearFormErrors();

          const schema = Yup.object().shape({
            document: Yup.string().required('O documento é obrigatório.'),
            contact_email: Yup.string().required('O e-mail é obrigatório.'),
            birthday: Yup.string()
              .nullable()
              .required('A data de nascimento é obrigatória.'),
            phone: Yup.string().required('O telefone é obrigatório.'),
            name: Yup.string().required('O usuário é obrigatório.'),
            nick: Yup.string(),
            level: Yup.string().required('O nível é obrigatório.'),
            departments: Yup.object().test(
              'at-least-one-true',
              'O usuário deve pertencer a pelo menos um departamento',
              object => {
                const findIfHasTrue = Object.values(object).some(
                  value => value === true
                );

                if (findIfHasTrue) {
                  return true;
                }

                return false;
              }
            ),
            work_time: Yup.object().shape({
              description: Yup.string().required('A descrição é obrigatória.'),
              start: Yup.string()
                .nullable()
                .required('A data de início é obrigatória.'),
              work_timetable: Yup.array()
                .min(1)
                .of(
                  Yup.object().shape({
                    day: Yup.string().required(
                      'O dia da semana é obrigatório.'
                    ),
                    in_hour: Yup.string().required(
                      'A hora de início é obrigatória.'
                    ),
                    out_hour: Yup.string().required(
                      'A hora de fim é obrigatória.'
                    ),
                  })
                )
                .required('Pelo menos um horário de trabalho é obrigatório.'),
            }),
            access_timetable: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  day: Yup.string().required('O dia da semana é obrigatório.'),
                  start: Yup.string().required(
                    'A hora de início é obrigatória.'
                  ),
                  end: Yup.string().required('A hora de fim é obrigatória.'),
                })
              )
              .required('Pelo menos um horário de acesso é obrigatório.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          let company_user_id;
          let company_user_old_id;
          let user_id;
          let user_old_id;
          let relationship_id;
          let old_relationship_id;

          const getOldData = await api.get('user-companies/old-data', {
            params: {
              company_id: company.id,
            },
          });

          const { old_company_id, mainid } = getOldData.data;

          if (data.relationship_id === '') {
            const relationshipData = {
              name: data.name,
              company_id: company.id,
              document_type: 1,
              document: data.document.replace(/[^0-9]+/g, ''),
              birthday: data.birthday,
              type: 2,
              start_day: new Date(),
              treatment_name: data.name.split(' ')[0],
              client_type: 1,
            };

            const createdRelationship = await api.post(
              'relationships',
              relationshipData,
              {
                params: {
                  old_company_id,
                  mainid,
                },
              }
            );

            relationship_id = createdRelationship.data.id;
            old_relationship_id = createdRelationship.data.old_id;
          } else {
            const relationshipData = {
              name: data.name,
              document: data.document.replace(/[^0-9]+/g, ''),
              birthday: data.birthday,
              start_day: new Date(),
              type: 2,
              client_type: 1,
            };

            const existedRelationship = await api.put(
              `relationships/${data.relationship_id}`,
              relationshipData,
              {
                params: {
                  mainid,
                },
              }
            );

            relationship_id = data.relationship_id;
            old_relationship_id = existedRelationship.data.old_id;
          }

          const contactsData = [
            {
              id: null,
              owner_id: relationship_id,
              type: 1,
              content: data.contact_email,
            },
            {
              id: null,
              owner_id: relationship_id,
              type: 3,
              content: data.phone.replace(/[^0-9]+/g, ''),
            },
          ];

          await api.post(
            'relationships/contacts',
            {
              company_id: company.id,
              contacts: contactsData,
            },
            {
              params: {
                old_company_id,
                mainid,
                relationship_id,
                old_relationship_id,
              },
            }
          );

          if (id) {
            company_user_id = id;
            const user_nick =
              data.nick.trim() !== '' ? data.nick : data.name.split(' ')[0];

            const [, companyUserResponse] = await Promise.all([
              api.put(`users/${data.user_id}`, {
                name: data.name,
                nick: user_nick,
                birthday: data.birthday,
              }),
              api.put(`company-users/${company_user_id}`, {
                pin: data.pin,
                level: data.level,
                visible_email: data.contact_email,
                dpt_accounting: data.departments.dpt_accounting,
                dpt_labour: data.departments.dpt_labour,
                dpt_tax: data.departments.dpt_tax,
                dpt_financial: data.departments.dpt_financial,
                dpt_admin: data.departments.dpt_admin,
                dpt_auxiliar: data.departments.dpt_auxiliar,
                hasKpi: data.hasKpi,
                active: data.active,
                relationship_id,
                substitute_user: data.substitute_user,
              }),
            ]);

            company_user_old_id = companyUserResponse.data.old_id;
          } else {
            const createdUser = await api.post('users', {
              email: data.contact_email,
              name: data.name,
              password: '123456',
              nick: data.contact_email,
              birthday: data.birthday,
              main_company: company.id,
              old_main_company: old_company_id,
            });

            user_id = createdUser.data.id;
            user_old_id = createdUser.data.old_id;

            const createdCompanyUser = await api.post('company-users', {
              user_id,
              company_id: company.id,
              pin: data.pin,
              level: data.level,
              visible_email: data.contact_email,
              dpt_accounting: data.departments.dpt_accounting,
              dpt_labour: data.departments.dpt_labour,
              dpt_tax: data.departments.dpt_tax,
              dpt_financial: data.departments.dpt_financial,
              dpt_admin: data.departments.dpt_admin,
              dpt_auxiliar: data.departments.dpt_auxiliar,
              hasKpi: data.hasKpi,
              relationship_id,
              old_company_id,
              mainid,
              user_old_id,
            });

            company_user_id = createdCompanyUser.data.id;
            company_user_old_id = createdCompanyUser.data.old_id;

            await api.post('dashboard', {
              user_id,
              company_id: company.id,
            });
          }

          if (data.work_time.id) {
            await Promise.all([
              api.put(`company-users/work-time/${data.work_time.id}`, {
                description: data.work_time.description,
                start: data.work_time.start,
                stop: data.work_time.stop || null,
                opening_balance: data.work_time.opening_balance,
              }),
              api.post(`company-users/work-timetable`, {
                work_timetable: data.work_time.work_timetable.map(time => ({
                  ...time,
                  company_id: company.id,
                  in_hour: setSeconds(
                    setMinutes(
                      setHours(new Date(), time.in_hour.split(':')[0]),
                      time.in_hour.split(':')[1]
                    ),
                    0
                  ),
                  out_hour: setSeconds(
                    setMinutes(
                      setHours(new Date(), time.out_hour.split(':')[0]),
                      time.out_hour.split(':')[1]
                    ),
                    0
                  ),
                  work_time_id: data.work_time.id,
                })),
                work_time_id: data.work_time.id,
                work_time_old_id: data.work_time.old_id,
                old_company_id,
                company_user_old_id,
                mainid,
              }),
              api.post(`company-users/access-timetable`, {
                access_timetable: data.access_timetable.map(time => ({
                  ...time,
                  company_id: company.id,
                  start: setSeconds(
                    setMinutes(
                      setHours(new Date(), time.start.split(':')[0]),
                      time.start.split(':')[1]
                    ),
                    0
                  ),
                  end: setSeconds(
                    setMinutes(
                      setHours(new Date(), time.end.split(':')[0]),
                      time.end.split(':')[1]
                    ),
                    0
                  ),
                })),
                company_user_id,
                company_user_old_id,
                old_company_id,
                mainid,
              }),
            ]);
          } else {
            const createdWorkTime = await api.post('company-users/work-time', {
              company_user_id,
              company_id: company.id,
              description: data.work_time.description,
              start: data.work_time.start,
              stop: data.work_time.stop,
              opening_balance: data.work_time.opening_balance,
              old_company_id,
              company_user_old_id,
              mainid,
            });

            const work_time_id = createdWorkTime.data.id;
            const work_time_old_id = createdWorkTime.data.old_id;

            await Promise.all([
              api.post(`company-users/work-timetable`, {
                work_timetable: data.work_time.work_timetable.map(time => ({
                  ...time,
                  in_hour: setMinutes(
                    setHours(new Date(), time.in_hour.split(':')[0]),
                    time.in_hour.split(':')[1]
                  ),
                  out_hour: setMinutes(
                    setHours(new Date(), time.out_hour.split(':')[0]),
                    time.out_hour.split(':')[1]
                  ),
                  company_id: company.id,
                })),
                work_time_id,
                work_time_old_id,
                old_company_id,
                company_user_old_id,
                mainid,
              }),
              api.post(`company-users/access-timetable`, {
                access_timetable: data.access_timetable.map(time => ({
                  ...time,
                  company_id: company.id,
                  start: setMinutes(
                    setHours(new Date(), time.start.split(':')[0]),
                    time.start.split(':')[1]
                  ),
                  end: setMinutes(
                    setHours(new Date(), time.end.split(':')[0]),
                    time.end.split(':')[1]
                  ),
                })),
                company_user_id,
                company_user_old_id,
                old_company_id,
                mainid,
              }),
            ]);
          }

          if (data.hasKpi) {
            await api.post(`/kpi/feedback`, {
              company_id: company.id,
              user_id: id ? data.user_id : user_id,
            });
          }

          toast.success('Usuário salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/user');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'departments') {
                setRequiredDepartmentErrorMessage(error.message);
              }
              if (error.path === 'work_time.work_timetable') {
                setRequiredWorkTimeErrorMessage(error.message);
              }
              if (error.path === 'access_timetable') {
                setRequiredAccessTimeErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);

            setSaveLoading(false);
          } else {
            toast.error(
              `${err.response.data.message || 'Falha ao salvar usuário'}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );

            setSaveLoading(false);
          }
        }
      }
    },
    [id, company, handleClearFormErrors]
  );

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/user')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const confirmRemove = useCallback((functionToExecute, itemId, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(itemId, index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleSearchByCpf = useCallback(
    async value => {
      if (company) {
        const formattedCpf = value.replace(/[^0-9]+/g, '');

        if (formattedCpf.length !== 11) {
          return;
        }

        setSearchCpfLoading(true);

        const { data } = await api.get('relationships', {
          params: {
            company_id: company.id,
            selectOnly: true,
            selectOnlyType: 'cpf',
            search: formattedCpf,
            type: 2,
          },
        });

        if (data.length > 0) {
          formRef.current.setFieldValue('relationship_id', data[0].id);
          formRef.current.setFieldValue('phone', `${data[0].contact}`);
          formRef.current.setFieldValue(
            'birthday',
            data[0].birthday ? parseISO(data[0].birthday) : null
          );
        }

        setSearchCpfLoading(false);
      }
    },
    [company]
  );

  const handleAddNewWorkTime = useCallback(() => {
    setUser(
      produce(user, draft => {
        const blankTime = {
          id: uuid(),
          day: '',
          in_hour: '',
          out_hour: '',
        };

        if (draft.work_time) {
          if (draft.work_time.work_timetable.length > 0) {
            draft.work_time.work_timetable.unshift(blankTime);
          } else if (draft.work_time.work_timetable) {
            draft.work_time.work_timetable[0] = blankTime;
          } else {
            draft.work_time.work_timetable = [blankTime];
          }
        } else {
          draft.work_time = {
            work_timetable: [blankTime],
          };
        }
      })
    );
  }, [user]);

  const handleRemoveWorkTime = useCallback(
    (timeId, index) => {
      setUser(
        produce(user, draft => {
          draft.work_time.work_timetable.splice(index, 1);
        })
      );
    },
    [user]
  );

  const handleAddNewAccessTime = useCallback(() => {
    setUser(
      produce(user, draft => {
        const blankTime = {
          id: uuid(),
          day: '',
          start: '',
          stop: '',
        };

        if (draft.access_timetable.length > 0) {
          draft.access_timetable.unshift(blankTime);
        } else if (draft.access_timetable) {
          draft.access_timetable[0] = blankTime;
        } else {
          draft.access_timetable = [blankTime];
        }
      })
    );
  }, [user]);

  const handleRemoveAccessTime = useCallback(
    (timeId, index) => {
      setUser(
        produce(user, draft => {
          draft.access_timetable.splice(index, 1);
        })
      );
    },
    [user]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaUserFriends size={20} color="#44546a" />
            <h1>Usuários</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button">
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {loading || !company || !user ? (
          <FormLoading className="loading" />
        ) : (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={user}
            >
              <BasicInfo>
                <h4>USUÁRIO</h4>
                <section>
                  <Input name="relationship_id" type="text" className="hide" />
                  <Input name="user_id" type="text" className="hide" />
                  <InputMask
                    name="document"
                    className="document"
                    label="CPF"
                    mask="999.999.999-99"
                    onChange={e => handleSearchByCpf(e.target.value)}
                  />
                  <Input name="name" className="name" label="Usuário" />
                  <Input name="nick" className="name" label="Tratamento" />
                  <Input
                    name="contact_email"
                    className="email"
                    label="E-mail para contato interno"
                  />
                  <DatePicker
                    name="birthday"
                    className="birthday"
                    label="Data de nascimento"
                  />
                </section>
                <section>
                  <InputMask
                    name="phone"
                    className="phone"
                    label="Telefone"
                    mask="(99) 99999-9999"
                  />
                  <Input name="pin" className="pin" label="Pin(Opcional)" />
                  <Select
                    name="level"
                    className="level"
                    label="Nível"
                    options={levelOptions}
                    placeholder="Selecione um nível"
                  />
                  <Checkbox name="hasKpi" className="active" label="Tem KPI" />
                  <Checkbox
                    name="active"
                    className="active"
                    label="Ativo"
                    onChange={() => setIsUserActive(is => !is)}
                  />

                  {!isUserActive && id && (
                    <Select
                      name="substitute_user"
                      className="substitute"
                      label="Direcionar compromissos para"
                      defaultValue={usersOptions[0]}
                      options={usersOptions}
                      placeholder="Selecione um usuário"
                    />
                  )}
                </section>
              </BasicInfo>
              <Departments>
                <h4>DEPARTAMENTOS</h4>

                {requiredDepartmentErrorMessage && (
                  <span>{requiredDepartmentErrorMessage}</span>
                )}

                <section>
                  <Scope path="departments">
                    <Checkbox
                      id="dpt_labour"
                      name="dpt_labour"
                      className="dpt_labour"
                      label="Trabalhista"
                    />
                    <Checkbox
                      id="dpt_tax"
                      name="dpt_tax"
                      className="dpt_tax"
                      label="Tributário"
                    />
                    <Checkbox
                      id="dpt_accounting"
                      name="dpt_accounting"
                      className="dpt_accounting"
                      label="Contábil"
                    />
                    <Checkbox
                      id="dpt_financial"
                      name="dpt_financial"
                      className="dpt_financial"
                      label="Financeiro"
                    />
                    <Checkbox
                      id="dpt_admin"
                      name="dpt_admin"
                      className="dpt_admin"
                      label="Administração"
                    />
                    <Checkbox
                      id="dpt_auxiliar"
                      name="dpt_auxiliar"
                      className="dpt_auxiliar"
                      label="Auxiliar"
                    />
                  </Scope>
                </section>
              </Departments>
              <WorkTime>
                <header>
                  <h4>HORÁRIOS DE TRABALHO</h4>
                  <button type="button" onClick={handleAddNewWorkTime}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredWorkTimeErrorMessage && (
                  <span>{requiredWorkTimeErrorMessage}</span>
                )}

                <section>
                  <Scope path="work_time">
                    <Input name="id" type="text" className="hide" readOnly />
                    <Input
                      name="old_id"
                      type="text"
                      className="hide"
                      readOnly
                    />
                    <Input
                      name="description"
                      className="work_time_description"
                      label="Descrição"
                    />
                    <DatePicker
                      name="start"
                      className="work_time_start"
                      label="Início"
                    />
                    <DatePicker
                      name="stop"
                      className="work_time_stop"
                      label="Fim"
                    />
                    <Input
                      name="opening_balance"
                      className="work_time_balance"
                      label="Saldo(minutos)"
                    />
                  </Scope>
                </section>
                <WorkTimetable>
                  {user?.work_time?.work_timetable?.map((time, index) => (
                    <Time key={time.id}>
                      <button
                        type="button"
                        onClick={() =>
                          confirmRemove(handleRemoveWorkTime, time.id, index)
                        }
                      >
                        <FaMinus size={10} />
                      </button>
                      <Scope path={`work_time.work_timetable[${index}]`}>
                        <section>
                          <Input
                            name="id"
                            type="text"
                            className="hide"
                            readOnly
                          />
                          <Select
                            name="day"
                            className="work_timetable_day"
                            label="Dia da semana"
                            placeholder="Selecione um dia"
                            options={dayOptions}
                          />
                          <InputMask
                            name="in_hour"
                            className="work_timetable_in_hour"
                            label="Início"
                            mask="99:99"
                          />
                          <InputMask
                            name="out_hour"
                            className="work_timetable_out_hour"
                            label="Fim"
                            mask="99:99"
                          />
                        </section>
                      </Scope>
                    </Time>
                  ))}
                </WorkTimetable>
              </WorkTime>
              <AccessTimetable>
                <header>
                  <h4>HORÁRIOS AUTORIZADOS</h4>
                  <button type="button" onClick={handleAddNewAccessTime}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredAccessTimeErrorMessage && (
                  <span>{requiredAccessTimeErrorMessage}</span>
                )}

                {user.access_timetable.map((day, index) => (
                  <Time key={day.id}>
                    <button
                      type="button"
                      onClick={() =>
                        confirmRemove(handleRemoveAccessTime, day.id, index)
                      }
                    >
                      <FaMinus size={10} />
                    </button>
                    <Scope path={`access_timetable[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="day"
                          className="access_timetable_day"
                          label="Dia da semana"
                          options={dayOptions}
                          placeholder="Selecione um dia"
                        />
                        <InputMask
                          name="start"
                          className="access_timetable_start"
                          label="Início"
                          mask="99:99"
                        />
                        <InputMask
                          name="end"
                          className="access_timetable_stop"
                          label="Fim"
                          mask="99:99"
                        />
                      </section>
                    </Scope>
                  </Time>
                ))}
              </AccessTimetable>
            </FormContainer>
          </Content>
        )}
      </Container>
      {(saveLoading || searchCpfLoading) && <Loading />}
    </>
  );
};

export default Form;
